import { IDataPoint } from "../data";
import { PlotDataPointFactory } from "./PlotDataPointFactory";

export class RangePlotDataPointFactory {

  private _factory = new PlotDataPointFactory();
  private _previousItem?: IDataPoint;
  private _previousValue: number = 0;
  private _previousItemValid: boolean = false;

  constructor(
    public _minValue: number | null,
    public _maxValue: number | null,
    private _valueScale: d3.ScaleLinear<number, number>
  ) {

  }

  public add(
    x: number,
    timeMs: number,
    value: number,
    valid: boolean
  ): void {
    // check if the new item is in range
    let defined = valid;
    if (this._minValue !== null && value < this._minValue) {
      defined = false;
    }
    if (this._maxValue !== null && value > this._maxValue) {
      defined = false;
    }
    const newItem: IDataPoint = {
      defined: defined,
      timeMs: timeMs,
      x: x,
      y: this._valueScale(value)
    };
    
    if (this._previousItem && this._previousItemValid && valid) {
      // we might need to interpolate
      if (this._minValue !== null) {
        if (this._previousValue < this._minValue && value > this._minValue) {
          // previous item was below the range and the new item is in (or above) the range
          this._addInterpolatedItem(value, newItem, this._minValue);
        }
      }
      if (this._maxValue !== null) {
        if (this._previousValue > this._maxValue && value < this._maxValue) {
          // previous item was above the range and the new item is in (or below) the range
          this._addInterpolatedItem(value, newItem, this._maxValue);
        }
      }
      if (this._maxValue !== null) {
        if (this._previousValue < this._maxValue && value > this._maxValue) {
          // previous item was in the range (could not be below because we interpolate above) and the new item is above the range
          this._addInterpolatedItem(value, newItem, this._maxValue);
        }
      }
      if (this._minValue !== null) {
        if (this._previousValue > this._minValue && value < this._minValue) {
          // previous item was in the range (could not be above because we interpolate above) and the new item is below the range
          this._addInterpolatedItem(value, newItem, this._minValue);
        }
      }
    }
    
    this._factory.add(newItem);
    this._previousItem = newItem;
    this._previousItemValid = valid;
    this._previousValue = value;
  }

  public getData(): IDataPoint[] {
    return this._factory.getData();
  }

  private _addInterpolatedItem(newValue: number, newItem: IDataPoint, targetValue: number) {
    if (!this._previousItem) {
      // should never happen, ignore
      return;
    }

    const totalChange = newValue - this._previousValue;
    if (totalChange == 0) {
      // previous point as at the boundary, ignore
      return;
    }
    const changeToTransition = targetValue - this._previousValue;
    const percent = Math.abs(changeToTransition / totalChange);
    const interpolatedTimeMs = this._previousItem.timeMs + (newItem.timeMs - this._previousItem.timeMs) * percent;
    const interpolatedX = this._previousItem.x + (newItem.x - this._previousItem.x) * percent;
    const interpolatedItem: IDataPoint = {
      defined: true, // interpolated items are always defined since they are at the min/max bounds
      timeMs: interpolatedTimeMs,
      x: interpolatedX,
      y: this._valueScale(targetValue)
    };
    this._factory.add(interpolatedItem);
    this._previousItem = interpolatedItem;
    this._previousValue = targetValue;
  }
}