
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

const moveListenerOptions = { passive: true, capture: true }

@Component({
})
export default class HubblePlotSlider extends Vue {

  @Prop({required: true})
  public min!: number;

  @Prop({required: true})
  public max!: number;

  @Prop({required: true})
  public value!: [number, number];

  public rawLeft: number = -Infinity;
  public rawRight: number = Infinity;
  private _currentSelection: 'left' | 'right' | 'none' = 'none';

  @Watch('value')
  public onValueChanged(): void {
    if (this.rawLeft != this.value[0] || this.rawRight != this.value[1])
    {
      this.rawLeft = this.value[0];
      this.rawRight = this.value[1];
      this._handleUpdated();
    }
  }

  @Emit()
  public input(range: [number, number]): [number, number] {
    return range;
  }

  public get leftMarkerPosition(): string {
    return (this.left - this.min) / (this.max - this.min) * 100 + '%';
  }

  public get rightMarkerPosition(): string {
    return (this.right - this.min) / (this.max - this.min) * 100 + '%';
  }

  public get leftCoverWidth(): string {
    return this.leftMarkerPosition;
  }

  public get rightCoverWidth(): string {
    return (100 - (this.right - this.min) / (this.max - this.min) * 100) + '%';
  }

  public get left() {
    return this._clamp(this.rawLeft);
  }

  public get right() {
    return this._clamp(this.rawRight);
  }

  public onMousedown(e: MouseEvent): void {
    e.preventDefault();

    this._handleStart(this._parseMouseMove(e));

    window.addEventListener('mousemove', this._onMouseMove, moveListenerOptions)
    window.addEventListener('mouseup', this._onMouseUp, { passive: false })
  }

  private _onMouseMove(e: MouseEvent): void {
    this._handleMove(this._parseMouseMove(e));
  }

  private _onMouseUp(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();

    this._handleStop();

    window.removeEventListener('mousemove', this._onMouseMove, moveListenerOptions);
    window.removeEventListener('mouseup', this._onMouseUp);
  }

  private _handleMove(value: number) {
      if (this._currentSelection == 'left') {
        this.rawLeft = value;
        this._handleUpdated();
      } else if (this._currentSelection == 'right') {
        this.rawRight = value;
        this._handleUpdated();
      }
  }

  private _handleStart(value: number): void {
    const a = Math.abs(value - this.left);
    const b = Math.abs(value - this.right);
    if (a < b) {
      // closer to the left handle, select it
      this._currentSelection = 'left';
    } else {
      // close to the right handle, select it
      this._currentSelection = 'right';
    }
    // trigger a move so we update on down
    this._handleMove(value);
  }

  private _handleStop(): void {
    this._currentSelection = 'none';
  }

  private _handleUpdated(): void {
    if (this.rawLeft > this.rawRight) {
      // left is larger than the right, flip which is currently selected
      this.rawRight = this.rawLeft;
      if (this._currentSelection == 'left') {
        this._currentSelection = 'right';
      }
      else if (this._currentSelection == 'right') {
        this._currentSelection = 'left';
      }
    }
    this.input([this.left, this.right]);
  }

  private _parseMouseMove(e: MouseEvent | TouchEvent): number {
    const boxSize = this.$el.getBoundingClientRect();
    const trackStart = boxSize.left;
    const trackLength = boxSize.width;
    const clickOffset = this._getPosition(e);

    // it is possible for left to be NaN, force to number
    const clickPos = Math.min(Math.max((clickOffset - trackStart) / trackLength, 0), 1) || 0;

    return this.min + clickPos * (this.max - this.min);
  }

  private _getPosition(e: MouseEvent | TouchEvent): number {
    if ('touches' in e && e.touches.length) {
        return e.touches[0].clientX;
    } else if ('changedTouches' in e && e.changedTouches.length) {
        return e.changedTouches[0].clientX;
    } else {
        return (e as MouseEvent).clientX;
    }
  }

  private _clamp(value: number) {
    return Math.max(this.min, Math.min(value, this.max));
  }
}

