import { IDataPoint } from "../data";

export class PlotDataPointFactory {
  private _data: IDataPoint[] = [];

  public getData(): IDataPoint[] {
    return this._data;
  }

  public add(
    newItem: IDataPoint
  ): void {
    if (this._data.length > 0){
      // check if we need to add an empty data point
      const maxGapMs = 1000 * 60 * 2;
      const previousItem = this._data[this._data.length - 1];
      const gap = newItem.timeMs - previousItem.timeMs;
      if (gap >= maxGapMs && previousItem.defined && newItem.defined) {
        // there is a gap between the new item and the previous one
        // add an empty value before the new item
        this._data.push({
            defined: false,
            timeMs: previousItem.timeMs,
            x: previousItem.x,
            y: previousItem.y
        });
      }
    }
    this._data.push(newItem);
  }
}