
import { CaseMode } from '@/enums';
import { settingsStore } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { HubblePlotTemperatureRange } from './data';
import { HubblePlotCalculator } from './util';

const hubblePlotCalculator = new HubblePlotCalculator();

@Component({
})
export default class HubblePlotYAxisScaleControl extends Vue {

  @Prop({required: true})
  public caseMode!: CaseMode;

  @Prop({required: true})
  public minPatientTemperature!: number;

  @Prop({required: true})
  public maxPatientTemperature!: number;

  public get minValue(): number {
    return this.range.min;
  }

  public get maxValue(): number {
    return this.range.max;
  }

  public get tallerGraph(): boolean {
    return settingsStore.tallerGraph;
  }

  public get range(): HubblePlotTemperatureRange {
    const patientTemperatureRange = hubblePlotCalculator.calcTemperatureRange(
      this.caseMode,
      settingsStore.showSinglePhase,
      this.minPatientTemperature,
      this.maxPatientTemperature,
      null,
      settingsStore.useBathTemperatureRange,
      settingsStore.showBathTemperature
    );
    // check if the custom range is outside the bounds
    const customRange = settingsStore.customPatientTemperatureRange;
    if (customRange) {
      if (customRange[0] < patientTemperatureRange.min || customRange[1] > patientTemperatureRange.max) {
        // outside the range, clear it
        settingsStore.setCustomPatientTemperatureRange(null);
      }
    }
    return patientTemperatureRange;
  }
  
  public setRangeValue(newRange: [number, number]) {
    if (newRange[0] === this.minValue && newRange[1] === this.maxValue) {
      settingsStore.setCustomPatientTemperatureRange(null);
    } else {
      settingsStore.setCustomPatientTemperatureRange(newRange);
    }
  }

  public get displayValue(): [number, number] {
    const range = settingsStore.customPatientTemperatureRange;
    if (range == null) {
      return [this.minValue, this.maxValue];
    } else {
      return range;
    }
  }
}

