
import { settingsStore } from '@/store';
import { DELETE_COLOR } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class TooltipToggleButton extends Vue {

  public items: string[] = ['right', 'top', 'disabled'];

  public get redColor(): string {
    return DELETE_COLOR;
  }

  public get selectedItemIndex(): number {
    return this.items.indexOf(this.selectedItem);
  }
  public set selectedItemIndex(index: number) {
    this.selectedItem = this.items[index];
  }

  public get selectedItem(): string {
    if (!this.tooltipEnabled) {
      return 'disabled';
    }
    return this.tooltipRight ? 'right' : 'top';
  }
  public set selectedItem(item: string) {
    switch (item) {
      case 'right': {
        this.tooltipEnabled = true;
        this.tooltipRight = true;
      }
      break;
      case 'top': {
        this.tooltipEnabled = true;
        this.tooltipRight = false;
      }
      break;
      case 'disabled': {
        this.tooltipEnabled = false;
        this.tooltipRight = true;
      }
      break;
    }
  }

  public get tooltipEnabled(): boolean {
    return settingsStore.tooltipEnabled;
  }
  public set tooltipEnabled(tooltipEnabled: boolean) {
    settingsStore.setTooltipEnabled(tooltipEnabled);
  }

  public get tooltipRight(): boolean {
    return settingsStore.tooltipRight;
  }
  public set tooltipRight(tooltipRight: boolean) {
    settingsStore.setTooltipRight(tooltipRight);
  }
}
