
import * as d3 from 'd3';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlotDataPoint, TimelineItem } from '@/interfaces';
import { TimelineItemType } from '@/enums';
import { HubblePlotCalculator, HubblePlotHoverLineRenderer, HubblePlotMarginCalculator, HubblePlotRenderer } from './util';
import { HubblePlotRenderOptions, HubblePlotRenderResult, IClosestPoint, ITimelineDataPoint } from './data';
import HubblePlotAlarmTooltip from './HubblePlotAlarmTooltip.vue';
import HubblePlotTooltip from './HubblePlotTooltip.vue';
import { throttleFunction } from '@/utils';

const hubblePlotCalculator = new HubblePlotCalculator();
const hubblePlotMarginCalculator = new HubblePlotMarginCalculator();
const hubblePlotRenderer = new HubblePlotRenderer();
const hubblePlotHoverLineRenderer = new HubblePlotHoverLineRenderer();

@Component({
  components: {
    HubblePlotTooltip,
    HubblePlotAlarmTooltip
  }
})
export default class HubblePlotSvg extends Vue {

  @Prop({required: true})
  public data!: PlotDataPoint[];

  @Prop({required: true})
  public timelineItems!: TimelineItem[];

  @Prop({required: true})
  public renderOptions!: HubblePlotRenderOptions;

  public lastHoverPoint: IClosestPoint = { x: 0, diff: 0, index: 0, point: null };
  public hoverTimelinePoint: ITimelineDataPoint | null = null;
  public tooltipAnchorX: number = 0;
  public tooltipAnchorY: number = 0;
  public alarmTooltipAnchorX: number = 0;
  public alarmTooltipAnchorY: number = 0;
  public renderResult: HubblePlotRenderResult = {
    timelinePoints: []
  };

  @Watch('data')
  public onDataChanged() {
    this.lastHoverPoint.point = null;
    this.updateSvg();
  }

  @Watch('renderOptions')
  public onRenderOptionsChanged() {
    this.updateSvg();
  }

  @Watch('timelineItems')
  public onTimelineItemsChanged() {
    this.updateSvg();
  }

  public updateSvg: () => void = throttleFunction(() => {
    this.updatePoints();
    this.updateHoverLine();
    this.updateHoverAlarm();
  }, 100);

  public get displayTimelineItems(): TimelineItem[] {
    const displayTypes: TimelineItemType[] = [
    ];
    if (this.renderOptions.showNotes) {
      displayTypes.push(TimelineItemType.userAnnotation);
      displayTypes.push(TimelineItemType.field);
      if (this.renderOptions.showSinglePhase) {
        displayTypes.push(TimelineItemType.phase);
      }
    }
    if (this.renderOptions.showAlarms) {
      displayTypes.push(TimelineItemType.alarm);
    }
    return this.timelineItems
      .filter((x) => displayTypes.includes(x.type));
  }

  public get margin() {
    return hubblePlotMarginCalculator.calcMargin(
      this.renderOptions
    );
  }

  public get width(): number {
    return Math.max(this.renderOptions.width, 0);
  }

  public get height(): number {
    return Math.max(this.renderOptions.height, 0);
  }

  private get graphWidth(): number {
    return this.width - this.margin.left - this.margin.right;
  }

  private get graphHeight(): number {
    return this.height - this.margin.top - this.margin.bottom;
  }

  public get scaleX(): d3.ScaleLinear<number, number> {
    return hubblePlotCalculator.calcScaleX(
      this.data,
      this.graphWidth,
      this.renderOptions.customTimeRange
    );
  }

  private updateHoverAlarm(): void {
    const alarmTooltipAnchor = hubblePlotHoverLineRenderer.calcAlarmTooltipAnchor(
      this.$el,
      this.hoverTimelinePoint,
      this.width,
      this.height,
      this.margin
    );
    this.alarmTooltipAnchorX = alarmTooltipAnchor.x;
    this.alarmTooltipAnchorY = alarmTooltipAnchor.y;
  }

  private updateHoverLine(): void {
    hubblePlotHoverLineRenderer.render(
      this.$el,
      this.graphWidth,
      this.graphHeight,
      this.margin,
      this.lastHoverPoint,
      this.height,
      this.renderOptions.tooltipEnabled
    );
    const tooltipAnchor = hubblePlotHoverLineRenderer.calcTooltipAnchor(
      this.$el,
      this.lastHoverPoint,
      this.width,
      this.margin,
      this.renderOptions.tooltipRight
    );
    this.tooltipAnchorX = tooltipAnchor.x;
    this.tooltipAnchorY = tooltipAnchor.y;
  }

  private updatePoints() {
    this.renderResult = hubblePlotRenderer.render(
      this.$el,
      this.data,
      this.displayTimelineItems,
      this.graphWidth,
      this.graphHeight,
      this.margin,
      this.renderOptions.caseMode,
      this.renderOptions.minPatientTemperature,
      this.renderOptions.maxPatientTemperature,
      this.scaleX,
      this.renderOptions
    );
  }

  public mouseover(data: { offsetX: number, offsetY: number }): void {
    let overlappingAlarm: ITimelineDataPoint | null = null;
    let closestPoint: IClosestPoint | null = null;
   
    overlappingAlarm = hubblePlotHoverLineRenderer.findOverlappingAlarm(
      data.offsetX,
      data.offsetY,
      this.renderResult.timelinePoints,
      this.margin,
      this.renderOptions.alarmSize
    );
    if (!overlappingAlarm) {
      // not hovering on an alarm
      // check if we are not in the margin
      if (data.offsetX >= this.margin.left && data.offsetX <= this.width - this.margin.right) {
        // find the closest point to show the hover line
        closestPoint = hubblePlotHoverLineRenderer.findClosestPoint(
          data.offsetX,
          this.data,
          this.margin,
          this.scaleX
        );
      }
    }

    if (this.hoverTimelinePoint !== overlappingAlarm) {
      this.hoverTimelinePoint = overlappingAlarm;
      this.updateHoverAlarm();
    }
    
    if (closestPoint && this.lastHoverPoint.point !== closestPoint.point) {
      // found a different point!
      this.lastHoverPoint = closestPoint;
      this.updateHoverLine();
    }
    if (!closestPoint && this.lastHoverPoint.point != null) {
      this.lastHoverPoint.point = null;
      this.updateHoverLine();
    }
  }

  public mouseleave(): void {
    if (this.lastHoverPoint.point !== null) {
      this.lastHoverPoint.point = null;
      this.updateHoverLine();
    }
    if (this.hoverTimelinePoint !== null) {
      this.hoverTimelinePoint = null;
      this.updateHoverAlarm();
    }
  }
}

