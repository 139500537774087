import { HubblePlotMargin, HubblePlotRenderOptions } from "../data";

export class HubblePlotMarginCalculator {
  constructor() {

  }

  public calcMargin(
    options: HubblePlotRenderOptions
  ): HubblePlotMargin {
    if (options.isZoomPreview) {
      return { top: 0, right: 0, bottom: 0, left: 0};
    }
    const margin: HubblePlotMargin = { top: 36, right: 7, bottom: 20, left: 7 };
    if (options.isFirst) {
      if (options.isShowingTemperature) {
        margin.left = 51;
      }
    }
    if (options.isLast) {
      if (options.showBathTemperature && !options.useBathTemperatureRange) {
        margin.right += 62;
      }
      if (options.showMachinePower) {
        margin.right += 47;
      }

      // always have some right margin for the tooltip
      if (margin.right < 50) {
        margin.right = 50;
      }
    }
    return margin;
  }

  public getMachinePowerAxisXOffset(
    options: HubblePlotRenderOptions
  ): number {
    return (options.showBathTemperature && !options.useBathTemperatureRange) ? 62 : 0;
  }
}