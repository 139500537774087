
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  }
})
export default class HorizontalScrollContainer extends Vue {
  @Prop({ required: true})
  public minWidth!: number;
}

