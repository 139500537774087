
import { AllLabels } from '@/interfaces';
import { settingsStore, labelsStore } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';
import TooltipToggleButton from '@/hubble/TooltipToggleButton.vue';

@Component({
  components: {
    TooltipToggleButton
  }
})
export default class CaseGraphDetailHeader extends Vue {

  private rawButtonGroupModel: any;

  @Prop({ required: true })
  public isThermogard!: boolean;

  @Prop({ required: true})
  public isBrainCool!: boolean;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
  
  public get showTargetTemperature(): boolean {
    return settingsStore.showTargetTemperature;
  }
  public set showTargetTemperature(showTargetTemperature: boolean) {
    settingsStore.setShowTargetTemperature(showTargetTemperature);
  }

  public get showBathTemperature(): boolean {
    return settingsStore.showBathTemperature;
  }
  public set showBathTemperature(showBathTemperature: boolean) {
    settingsStore.setShowBathTemperature(showBathTemperature);
  }

  public get showPatientTemperature(): boolean {
    return settingsStore.showPatientTemperature;
  }
  public set showPatientTemperature(showPatientTemperature: boolean) {
    settingsStore.setShowPatientTemperature(showPatientTemperature);
  }

  public get showMachinePower(): boolean {
    return settingsStore.showMachinePower;
  }
  public set showMachinePower(showMachinePower: boolean) {
    settingsStore.setShowMachinePower(showMachinePower);
  }

  public get showAlarms(): boolean {
    return settingsStore.showAlarms;
  }
  public set showAlarms(showAlarms: boolean) {
    settingsStore.setShowAlarms(showAlarms);
  }

  public get showNotes(): boolean {
    return settingsStore.showNotes;
  }
  public set showNotes(showNotes: boolean) {
    settingsStore.setShowNotes(showNotes);
  }

  public get showState(): boolean {
    return settingsStore.showState;
  }
  public set showState(showState: boolean) {
    settingsStore.setShowState(showState);
  }

  public get showSinglePhase(): boolean {
    return settingsStore.showSinglePhase;
  }
  public set showSinglePhase(showSinglePhase: boolean) {
    settingsStore.setShowSinglePhase(showSinglePhase);
  }

  public get multiColorPatientTemperature(): boolean {
    return settingsStore.multiColorPatientTemperature;
  }
  public set multiColorPatientTemperature(multiColorPatientTemperature: boolean) {
    settingsStore.setMultiColorPatientTemperature(multiColorPatientTemperature);
  }

  public toggleTallerGraph(): void {
    this.tallerGraph = !this.tallerGraph;
  }

  public get tallerGraph(): boolean {
    return settingsStore.tallerGraph;
  }
  public set tallerGraph(tallerGraph: boolean) {
    settingsStore.setTallerGraph(tallerGraph);
  }

  public get buttonGroupModel(): any {
    return this.rawButtonGroupModel;
  }
  public set buttonGroupModel(buttonGroupModel: any) {
    this.rawButtonGroupModel = buttonGroupModel;
  }

  public toggleTooltip(): void {
    this.tooltipEnabled = !this.tooltipEnabled;
  }

  public get tooltipEnabled(): boolean {
    return settingsStore.tooltipEnabled;
  }
  public set tooltipEnabled(tooltipEnabled: boolean) {
    settingsStore.setTooltipEnabled(tooltipEnabled);
  }

  public get useBathTemperatureRange(): boolean {
    return settingsStore.useBathTemperatureRange;
  }
  public set useBathTemperatureRange(useBathTemperatureRange: boolean) {
    settingsStore.setUseBathTemperatureRange(useBathTemperatureRange);
  }
}
