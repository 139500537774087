
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AllLabels, PlotDataPoint } from '@/interfaces';
import { DateWrapper, graphElapsedTimeFormat, translateMachineState } from '@/utils';
import { labelsStore, settingsStore } from '@/store';

@Component({
})
export default class HubblePlotTooltip extends Vue {
  
  public offset: number = 10;
  public positionX: string = '0px';
  public positionY: string = '0px';

  @Prop({required: true})
  public isThermogard!: boolean;

  @Prop({required: true})
  public isBrainCool!: boolean;

  @Prop({required: true})
  public point!: PlotDataPoint;

  @Prop({required: true})
  public startTime!: DateWrapper;

  @Prop({required: true})
  public anchorX!: number;

  @Prop({required: true})
  public anchorY!: number;

  @Watch("anchorX")
  public onAnchorXUpdate(): void {
    this._updatePositions();
  }

  @Watch("anchorY")
  public onAnchorYUpdate(): void {
    this._updatePositions();
  }

  public mounted(): void {
    this._updatePositions();
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get displayTargetTemp(): string {
    if (this.isThermogard) {
      if (this.point.targetTemp === 0) {
        return this.targetTemp;
      } else {
        return this.targetTemp + ' (' + this.targetTempSuffix + ')';
      }
    } else {
      return this.targetTemp;
    }
  }

  public get targetTemp(): string {
    return this._getDisplayTemperature(this.point.targetTemp);
  }

  public get targetTempSuffix(): string {
    const controlMode = this.point.controlMode.toUpperCase().trim();
    switch (controlMode) {
      case 'FEVER': {
        return this.labels.temp.casesPlotTooltipFeverMode;
      }
      case 'MAX': {
        return this.labels.temp.casesPlotTooltipMaxMode;
      }
      case 'WARMING': {
        return this.labels.temp.casesPlotTooltipWarmingMode;
      }
      default: {
        const controlRate = Number.parseFloat(controlMode);
        if (isNaN(controlRate)) {
          // not a number or an exepected controlMode
          return this.labels.temp.casesPlotTooltipUnknownMode;
        } else {
          return controlMode + this.labels.temp.casesPlotTooltipControlModeUnits;
        }
      }
    }
  }

  public get bathTemp(): string {
    return this._getDisplayTemperature(this.point.bathTemp);
  }

  public get patientTemp(): string {
    return this._getDisplayTemperature(this.point.patientTemp);
  }

  public get power(): string {
    if (this.point.power === 0 && this.point.state !== 'Run') {
      return '---';
    } else {
      return this.point.power.toFixed(0) + '%';
    }
  }

  public get time(): string {
    const relativeTime = graphElapsedTimeFormat(this.point.timeMs);
    const absoluteTime = this.startTime.plusMilliseconds(this.point.timeMs).toDisplayDateTime(settingsStore.use12HourClock);
    return `${relativeTime} (${absoluteTime})`;
  }

  public get state(): string {
    return translateMachineState(this.labels, this.point.state);
  }

  private _updatePositions() {
    this._updatePositionX();
    this._updatePositionY();
    setTimeout(() => {
      this._updatePositionX();
      this._updatePositionY();
    });
  }

  private _getDisplayTemperature(temperature: number): string {
    if (temperature === 0) {
      return '---';
    } else {
      return temperature + '°C';
    }
  }

  private _updatePositionX(): void {
    const width = this.$el?.getBoundingClientRect().width ?? 0;
    let left;
    if (settingsStore.tooltipRight) {
      // tooltip on the right (or left if right doesn't fit)
      left = this.anchorX + this.offset;
      let right = left + width;
      if (window.innerWidth - right < 20) {
        // not enough space on the right of the hover, put it on the left
        left = this.anchorX - width - this.offset;
      }
    } else {
      // tooltip is above, so center it
      left = this.anchorX - width / 2;
      let right = left + width;
      if (window.innerWidth - right < 20) {
        // not enough space to the right, shift it left
        left = window.innerWidth - 20 - width;
      }
    }
    this.positionX = left + 'px';
  }

  private _updatePositionY(): void {
    const height = this.$el?.getBoundingClientRect().height ?? 0;
    let top: number;
    if (settingsStore.tooltipRight) {
      // tooltip to the right or left, center it
      top = this.anchorY - height / 2;  
    } else {
      // tooltip is above, shift up
      top = this.anchorY - height - 37;
    }
    this.positionY = top + 'px';
  }
}

