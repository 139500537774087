import { CaseMode } from '@/enums';
import { PlotDataPoint } from '@/interfaces';
import * as d3 from 'd3';
import { HubblePlotTemperatureRange, IDataPoint } from '../data';

const MS_PER_HOUR = 1000 * 60 * 60;

export class HubblePlotCalculator {
  constructor() {

  }

  public calcTimeTicks(
    data: PlotDataPoint[],
    graphHeight: number,
    scaleX: d3.ScaleLinear<number, number>
  ): IDataPoint[] {
    const tickPoints: IDataPoint[] = [];
    let minTimeMs: number;
    let maxTimeMs: number;
    if (data.length > 0) {
      minTimeMs = data[0].timeMs;
      maxTimeMs = data[data.length - 1].timeMs;
    } else {
      minTimeMs = 0;
      maxTimeMs = 100;
    }
    const tickStartTime = Math.ceil(minTimeMs / MS_PER_HOUR) * MS_PER_HOUR;
    for(let tickTime = tickStartTime; tickTime < maxTimeMs; tickTime += MS_PER_HOUR) {
      tickPoints.push({
        timeMs: tickTime,
        defined: true,
        x: scaleX(tickTime),
        y: graphHeight
      });
    }
    return tickPoints;
  }

  public calcScaleX(
    data: PlotDataPoint[],
    graphWidth: number,
    customTimeRange: [number, number] | null
  ): d3.ScaleLinear<number, number> {
    let minTimeMs: number;
    let maxTimeMs: number;
    if (customTimeRange != null) {
      minTimeMs = customTimeRange[0];
      maxTimeMs = customTimeRange[1];
    }
    else if (data.length > 0) {
      minTimeMs = data[0].timeMs;
      maxTimeMs = data[data.length - 1].timeMs;
    } else {
      minTimeMs = 0;
      maxTimeMs = 100;
    }
    return d3
      .scaleLinear()
      .range([0, graphWidth])
      .domain([minTimeMs, maxTimeMs]);
  }

  public calcBathTemperatureRange(
    useBathTemperatureRange: boolean,
    customRange: [number,  number] | null,
  ): HubblePlotTemperatureRange {
    let minTemperature: number;
    let maxTemperature: number;
    if (customRange && useBathTemperatureRange) {
      // custom range was specified, use that instead
      minTemperature = customRange[0];
      maxTemperature = customRange[1];
    } else {
      minTemperature = 0;
      maxTemperature = 42;
    }
    const ticks = this._calcTemperatureTicks(minTemperature, maxTemperature);
    return {
      min: minTemperature,
      max: maxTemperature,
      ticks: ticks
    };
  }

  public calcTemperatureRange(
    caseMode: CaseMode,
    showSinglePhase: boolean,
    minPatientTemperature: number,
    maxPatientTemperature: number,
    customRange: [number, number] | null,
    useBathTemperatureRange: boolean,
    showBathTemperature: boolean
  ): HubblePlotTemperatureRange {
    if (useBathTemperatureRange && showBathTemperature) {
      return this.calcBathTemperatureRange(useBathTemperatureRange, customRange);
    }

    let minPatientTemperatureRange: number;
    let maxPatientTemperatureRange: number;
    let pushTemperatureBoundaries: boolean;

    if (customRange) {
      // custom range was specified, use that instead
      minPatientTemperatureRange = customRange[0];
      maxPatientTemperatureRange = customRange[1];
    } else {
      // calculate the default range
      switch (caseMode) {
        case CaseMode.Cooling:
          minPatientTemperatureRange = 28;
          maxPatientTemperatureRange = 40;
          pushTemperatureBoundaries = showSinglePhase;
          break;
        case CaseMode.Normothermia:
        case CaseMode.Warming: {
          minPatientTemperatureRange = 34;
          maxPatientTemperatureRange = 38;
          pushTemperatureBoundaries = true;
          break;
        }
        case CaseMode.Unknown:
        default:
          minPatientTemperatureRange = 28;
          maxPatientTemperatureRange = 40;
          pushTemperatureBoundaries = true;
          break;
      }
      if (pushTemperatureBoundaries) {
        minPatientTemperatureRange = Math.floor(Math.min(minPatientTemperature, minPatientTemperatureRange));
        maxPatientTemperatureRange = Math.ceil(Math.max(maxPatientTemperature, maxPatientTemperatureRange));
      }
    }

    const temperatureScaleTicks = this._calcTemperatureTicks(minPatientTemperatureRange, maxPatientTemperatureRange);

    const result: HubblePlotTemperatureRange = {
      min: minPatientTemperatureRange,
      max: maxPatientTemperatureRange,
      ticks: temperatureScaleTicks
    };
    return result;
  }

  private _calcTemperatureTicks(minTemperature: number, maxTemperature: number): number[] {
    // calculate the ticks
    const totalRange = maxTemperature - minTemperature;
    let temperatureScaleTickInterval = Math.floor(totalRange / 5);
    if (temperatureScaleTickInterval < 1) {
      temperatureScaleTickInterval = 1;
    }
    
    const temperatureScaleTicks: number[] = [];
    // make sure the scale always includes the bottom of the scale
    temperatureScaleTicks.push(minTemperature);

    let tickStart = minTemperature;
    if (!Number.isInteger(tickStart)) {
      tickStart = Math.floor(minTemperature + 1);
    }
    for (let tickTemp = tickStart; tickTemp <= maxTemperature; tickTemp += temperatureScaleTickInterval) {
      // don't include ticks if they are too close to the min/max
      const absoluteTooClose = Math.abs(tickTemp - minTemperature) < 0.5 || Math.abs(tickTemp - maxTemperature) < 0.5;
      // 7% used based on size of text and graph
      const percentageTooClose = Math.abs(tickTemp - minTemperature)/totalRange < 0.07 || Math.abs(tickTemp - maxTemperature)/totalRange < 0.07;
      if (!absoluteTooClose && !percentageTooClose) {
        temperatureScaleTicks.push(tickTemp);
      }
    }
    // make sure the scale always includes the top of the scale
    temperatureScaleTicks.push(maxTemperature);

    return temperatureScaleTicks;
  }
}