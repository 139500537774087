
import TabItem from '@/components/ui/tabs/TabItem.vue';
import Tabs from '@/components/ui/tabs/Tabs.vue';
import { AllLabels, CaseData, PlotData, PlotDataPoint } from '@/interfaces';
import { demoStore, labelsStore, settingsStore } from '@/store';
import { caseDataProviderKey, isThermogardDevice, isBrainCoolDevice, delay } from '@/utils';
import { Component, InjectReactive, Ref, Vue } from 'vue-property-decorator';
import CaseGraphDetailHeader from './CaseGraphDetailHeader.vue';
import HubblePlot from './HubblePlot/HubblePlot.vue';
import HubblePlotLegend from './HubblePlot/HubblePlotLegend.vue';
import HubblePlotWrapper from './HubblePlot/HubblePlotWrapper.vue';
import { EventUnsubscribe, ExportGraphEvent, ToastErrorEvent, eventService } from '@/services';
import saveAs from 'file-saver';
import { toBlob } from 'html-to-image';
import AppAlertDialog from '@/components/ui/AppAlertDialog.vue';

@Component({
  components: {
    AppAlertDialog,
    CaseGraphDetailHeader,
    Tabs,
    TabItem,
    HubblePlot,
    HubblePlotLegend,
    HubblePlotWrapper,
  }
})
export default class CaseGraph extends Vue {

  private _unsubscribe!: EventUnsubscribe;
  public exporting: boolean = false;
  public showUnsupportedDialogMessage: boolean = false;
  public showIdentifier: boolean = false;

  @Ref('exportContent')
  private exportContent: Tabs | undefined;

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get isThermogard(): boolean {
    return isThermogardDevice(this.caseData);
  }

  public get isBrainCool(): boolean {
    return isBrainCoolDevice(this.caseData);
  } 

  public get plotData(): PlotData {
    const result: PlotData = {
      events: this.caseData.events,
      points: this.caseData.graphData,
      phases: this.caseData.phases
    };
    return result;
  }

  public get tallerGraph(): boolean {
    return settingsStore.tallerGraph;
  }

  public get unsupportedDialogMessage(): string {
    return this.labels.temp.casesDialogCsvExportNotSupported + ' ' + this.caseData.deviceType;
  }

  public get identifier(): string {
    const prefix = demoStore.isDemoActive ? 'Demo ' : '';
    const value =  this.caseData.caseDateTime.toDisplayDateTime(settingsStore.use12HourClock);
    return prefix + value;
  }

  public mounted(): void {
    this._unsubscribe = eventService.subscribe(ExportGraphEvent, (exportGraphEvent) => this._onExportEvent(exportGraphEvent));
  }

  public beforeDestroy(): void {
    this._unsubscribe();
  }

  public onDismissDialog(): void {
    this.showUnsupportedDialogMessage = false;
  }

  private async _onExportEvent(exportGraphEvent: ExportGraphEvent): Promise<void> {
    if (exportGraphEvent.exportCsv) {
      if (this.isThermogard) {
        await this._exportGraphCsv();
      } else {
        this.showUnsupportedDialogMessage = true;
      }
    } else {
      await this._exportGraphPng();
    }
  }

  private async _exportGraphCsv(): Promise<void> {
    await this._export('treatment.csv', async () => {
      const fileString = this._createCsvFileData(this.caseData);
      // const data = await CaseApi.getCsvFile(this.caseData.id);
      return new Blob([fileString]);
    });
  }

  private async _exportGraphPng(): Promise<void> {
    await this._export('treatment.png', async () => {
      if (demoStore.isDemoActive) {
        this.showIdentifier = true;
      }
      try {
        this.$forceUpdate();
        await delay(1);
        const element = (this.exportContent as Tabs).tabsContent as HTMLElement;
        // find body element
        let appElement = element;
        while (appElement.parentElement && appElement.className != 'app') {
          appElement = appElement.parentElement;
        }
        const origWidth = appElement.style.width;
        try {
          appElement.style.width = '1920px';
          this.$forceUpdate();
          await delay(100);
          const data = await toBlob(appElement);
          // TODO: can imageContent be null?
          return data!;
        } finally {
          appElement.style.width = origWidth;
        }
      } finally {
        this.showIdentifier = false;
      }
    });
  }

  private async _export(fileName: string, getDataMethod: () => Promise<Blob>) {
    fileName = this.identifier + '_' + fileName;
    this.exporting = true;
    try {
      const content = await getDataMethod();
      this.exporting  = false;
      if (window.saveAs) {
        window.saveAs(content, fileName);
      } else {
        saveAs(content, fileName);
      }
    } catch (e) {
      eventService.publish(new ToastErrorEvent());
    } finally {
      this.exporting = false;
    }
  }

  private _createCsvFileData(caseData: CaseData): string {
    // unpack some values
    const graphData: PlotDataPoint[] = caseData.graphData;
    const startTime = caseData.caseDateTime;

    const labels: string[] = [
      'Date/Time',
      'Patient Temp',
      'Target Temp',
      'Bath Temp',
      'State'
    ];
    const rows: string[] = [
      this._createCsvRow(labels)
    ];
    graphData.forEach((graphDataPoint) => {
      const rowData: string[] = [
        startTime.plusMilliseconds(graphDataPoint.timeMs).toDisplayDateTime(settingsStore.use12HourClock),
        graphDataPoint.patientTemp.toString(),
        graphDataPoint.targetTemp.toString(),
        graphDataPoint.bathTemp.toString(),
        graphDataPoint.state
      ];
      rows.push(this._createCsvRow(rowData));
    });
    const fileString = rows.join('\n');
    return fileString;
  }

  private _createCsvRow(columns: string[]): string {
    return columns
      .map((column) => column.replace('"', '""')) // escape quotes
      .map((column) => `"${column}"`) // wrap values in quotes
      .join(','); // separate with commas
  }
}
