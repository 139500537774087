
import { AllLabels, CaseData } from '@/interfaces';
import { labelsStore, uploadStore } from '@/store';
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import Footer from '@/components/common/Footer.vue';
import ResultsPageContent from './ResultsPageContent.vue';
import { caseDataProviderKey } from '@/utils';

@Component({
  components: {
    Footer,
    ResultsPageContent
  }
})
export default class ResultsPage extends Vue {

  @ProvideReactive(caseDataProviderKey)
  public get selectedCaseData(): CaseData | null {
    return uploadStore.selectedCase;
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }
}

