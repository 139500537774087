
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
import { AllLabels, CaseData, PlotData, PlotDataPoint, QuestionnaireResult, TimelineItem } from '@/interfaces';
import HubblePlot from './HubblePlot.vue';
import Loader from '@/components/ui/Loader.vue';
import { CaseMode } from '@/enums';
import { settingsStore, labelsStore, questionnaireStore } from '@/store';
import { caseDataProviderKey, DateWrapper, getAllTimelineItems, isThermogardDevice, isBrainCoolDevice, translatePhaseName } from '@/utils';
import HubblePlotYAxisScaleControl from './HubblePlotYAxisScaleControl.vue';
import { DELETE_COLOR } from '@/utils/Constants';

@Component({
  components: {
    HubblePlot,
    HubblePlotYAxisScaleControl,
    Loader
  }
})
export default class HubblePlotWrapper extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;
  
  @Prop({ required: true })
  public data!: PlotData;

  @Prop({ default: false })
  public loading!: boolean;

  public refreshKey: number = 0;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get questionnaire(): QuestionnaireResult | undefined {
    return questionnaireStore.getQuestionnaireResult(this.caseData);
  }

  public get caseMode(): CaseMode {
    return this.caseData.caseMode;
  }

  public get isThermogard(): boolean {
    return isThermogardDevice(this.caseData);
  }

  public get isBrainCool(): boolean {
    return isBrainCoolDevice(this.caseData);
  }

  public get startTime(): DateWrapper {
    return this.caseData.caseDateTime;
  }

  public get segments(): PlotDataPhasePoints[] {
    if (settingsStore.showSinglePhase) {
      let startIndex = 0;
      if (this.data.phases.length > 0) {
        startIndex = this.data.phases[0].startIndex;
      }
      return [{
        points: this.data.points.slice(startIndex),
        phaseName: this.labels.temp.casesPlotSinglePhaseName,
        segIndex: 0
      }];
    } else {
      return this.data.phases.map<PlotDataPhasePoints>((sep, sepIdx) => {
        return {
          points: this.data.points.slice(sep.startIndex, sep.endIndex),
          phaseName: translatePhaseName(this.labels, sep.phaseName),
          segIndex: sepIdx
        };
      });
    }
  }

  public get timelineItems(): TimelineItem[] {
    return getAllTimelineItems(
      this.caseData,
      this.questionnaire,
      this.labels
    );
  }

  public get minPatientTemperature(): number {
    return this._getMinPatientTemperature();
  }

  public get maxPatientTemperature(): number {
    return this._getMaxPatientTemperature();
  }

  public onXAxisZoomChanged(): void {
    this.refreshKey++;
  }

  public get hasZoom(): boolean {
    // we need to reference the refreshKey so that this re-evaluates when refreshKey is modified
    this.refreshKey;
    const yAxisZoom = settingsStore.customPatientTemperatureRange != null;
    const xAxisZoom = this._plots.some((plot) => {
      return plot.hasXAxisZoom;
    });
    return xAxisZoom || yAxisZoom;
  }

  public cancelZoom(): void {
    settingsStore.setCustomPatientTemperatureRange(null);
    this._plots.forEach((plot) => {
      plot.cancelZoom();
    });
  }

  public get redColor(): string {
    return DELETE_COLOR;
  }

  private _getMinPatientTemperature(): number {
    let minPatientTemperature = 0;
    let foundNonZero = false;
    this.data.points.forEach((point) => {
      if (point.patientTemp > 0) {
        if (!foundNonZero) {
          // first non zero value, set it as the minimum
          minPatientTemperature = point.patientTemp;
          foundNonZero = true;
        } else {
          minPatientTemperature = Math.min(minPatientTemperature, point.patientTemp);
        }
      }
    });
    return minPatientTemperature;
  }

  private _getMaxPatientTemperature(): number {
    let maxPatientTemperature = 0;
    let foundValid = false;
    this.data.points.forEach((point) => {
      if (point.patientTemp > 0 && point.patientTemp < 50) {
        if (!foundValid) {
          // first valid, set it as the maximum
          maxPatientTemperature = point.patientTemp;
          foundValid = true;
        } else {
          maxPatientTemperature = Math.max(maxPatientTemperature, point.patientTemp);
        }
      }
    });
    return maxPatientTemperature;
  }

  private get _plots(): Array<HubblePlot> {
    // we need to reference the refreshKey so that this re-evaluates when refreshKey is modified
    this.refreshKey;
    const result = this.$refs.plots || [] as any;
    return result;
  }
}

interface PlotDataPhasePoints {
  points: PlotDataPoint[];
  phaseName: string;
  segIndex: number;
}

