
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import CaseFieldItem from '@/components/ui/CaseFieldItem.vue';
import { caseDataProviderKey, isBrainCoolDevice } from '@/utils';
import { AllLabels, CaseData, QuestionnaireResult } from '@/interfaces';
import { labelsStore, questionnaireStore } from '@/store';

@Component({
  components: {
    CaseFieldItem,
  }
})
export default class QuestionnaireInfo extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get questionnaire(): QuestionnaireResult | undefined {
    return questionnaireStore.getQuestionnaireResult(this.caseData);
  }

  public get caseTypeHeading(): string {
    return this.labels.temp.questionnaireCaseTypeLabel;
  }

  public get caseTypeValue(): string {
    if (this.questionnaire?.customCaseType) {
      return this.questionnaire.customCaseType;
    } else {
      return this.questionnaire?.caseType || 'Unknown';
    }
  }

  public get peripheralTypeHeading(): string {
    if (isBrainCoolDevice(this.caseData)) {
      // is BrainCool
      return this.labels.temp.casesSystemInfoPadType;
    } else {
      // is not BrainCool
      return this.labels.temp.questionnairePeripheralTypeLabel;
    }
  }

  public get peripheralTypeValue(): string {
    if (isBrainCoolDevice(this.caseData)) {
      // is BrainCool
      const connectedPads: string[] = [];
      if (this.caseData.headPadConnected) {
        connectedPads.push(this.labels.temp.casesSystemInfoHeadPad);
      }
      if (this.caseData.torsoPadConnected) {
        connectedPads.push(this.labels.temp.casesSystemInfoTorsoPad);
      }
      if (this.caseData.thighPadConnected) {
        connectedPads.push(this.labels.temp.casesSystemInfoThighPad);
      }
      if (connectedPads.length === 0) {
        return 'Unknown';
      } else {
        return connectedPads.join(', ');
      }
    } else {
      // is not BrainCool
      return this.questionnaire?.peripheralType || 'Unknown';
    }
  }

  public get patientWeightHeading(): string {
    return this.labels.temp.questionnairePatientWeightLabel;
  }

  public get patientWeightValue(): string {
    if (!this.questionnaire) {
      return 'Unknown';
    } else {
      return this.questionnaire.patientWeight;
    }    
  }

  public get patientOutcomeHeading(): string {
    return this.labels.temp.questionnairePatientOutcomeLabel;
  }

  public get patientOutcomeValue(): string {
    if (!this.questionnaire) {
      return 'Unknown';
    } else {
      return this.questionnaire.patientOutcomeUnit + ' ' + this.questionnaire.patientOutcome;
    }
  }
  
}
