

import Loader from '@/components/ui/Loader.vue';
import { AllLabels, CaseData, QuestionnaireResult, TimelineItem } from '@/interfaces';
import { labelsStore, questionnaireStore, settingsStore } from '@/store';
import { caseDataProviderKey, getAllTimelineItems } from '@/utils';
import { Component, InjectReactive, Vue } from 'vue-property-decorator';

interface TimelineDisplayItem {
    time: string;
    message: string;
    index: number;
}

@Component({
  components: {
    Loader
  }
})
export default class CaseTimeline extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get questionnaire(): QuestionnaireResult | undefined {
    return questionnaireStore.getQuestionnaireResult(this.caseData);
  }

  public get timelineDisplayItems(): TimelineDisplayItem[] {
    // already sorted, no need to sort
    return this.timelineItems
      .map((item, index) => {
        return this._createDisplayItem(item, index);
      });
  }

  public get timelineItems(): TimelineItem[] {
    return getAllTimelineItems(
      this.caseData,
      this.questionnaire,
      this.labels
    );
  }

  private _createDisplayItem(item: TimelineItem, index: number): TimelineDisplayItem {
    return {
      time: item.time.toDisplayDateTime(settingsStore.use12HourClock),
      message: item.message,
      index: index
    };
  }
}

