import { TimelineItemType } from '@/enums';
import { PlotDataPoint } from '@/interfaces';
import * as d3 from 'd3';
import { HubblePlotMargin, HubblePlotTooltipAnchor, IClosestPoint, ITimelineDataPoint } from '../data';

export class HubblePlotHoverLineRenderer {

  constructor() {
    
  }

  public render(
    $el: Element,
    graphWidth: number,
    graphHeight: number,
    margin: HubblePlotMargin,
    lastHoverPoint: IClosestPoint,
    height: number,
    tooltipEnabled: boolean
  ): void {
    const svgElem = $el.querySelector('svg');
    const svg = d3.select(svgElem);

    svg.selectAll(".hover-line--container").remove();

    if (graphWidth <= 0 || graphHeight <= 0) {
      // we are too small to graph, don't try
      return;
    }
    if (!lastHoverPoint.point) {
      // no hover point!
      return;
    }
    if (!tooltipEnabled) {
      // tooltip is disabled, don't show the line
      return;
    }
    
    // need to append so the hover line is always last
    const g = svg.append('g')
      .attr('transform', 'translate(' + margin.left + ',0)')
      .classed('hover-line--container hover-line', true);

    const triangleOffset = 5;
    const lineDefinition = d3
      .line()(
        [
          [lastHoverPoint.x, 10], // top
          [lastHoverPoint.x, height - margin.bottom - triangleOffset], // bottom
          [lastHoverPoint.x - triangleOffset, height - margin.bottom], // left leg
          [lastHoverPoint.x, height - margin.bottom - triangleOffset], // back to bottom
          [lastHoverPoint.x + triangleOffset, height - margin.bottom], // right left
          [lastHoverPoint.x, height - margin.bottom - triangleOffset], // back to bottom
        ]
    );

    g.append('path')
      .datum([lastHoverPoint])
      .classed('hover-line', true)
      .attr('d', lineDefinition);
      
    g.append('circle')
      .classed('hover-line--circle hover-line', true)
      .attr('r', 9.5)
      .attr('cx', lastHoverPoint.x)
      .attr('cy', 10);
  }

  public calcTooltipAnchor(
    $el: Element,
    lastHoverPoint: IClosestPoint,
    width: number,
    margin: HubblePlotMargin,
    tooltipRight: boolean
  ): HubblePlotTooltipAnchor {
    if (!lastHoverPoint.point) {
      // no hover point!
      return {
        x: 0,
        y: 0
      };
    }
    // get the target position
    const box = $el;
    const boxSize = box.getBoundingClientRect();
    const graphTop = boxSize.top + margin.top;
    const graphBottom = boxSize.bottom - margin.bottom;
    const x = boxSize.right - width + lastHoverPoint.x + margin.left;
    let y: number;
    if (tooltipRight) {
      // center it
      y = (graphTop + graphBottom) / 2 
    } else {
      // use the top
      y = graphTop;
    }
    return {
      x: x,
      y: y
    };
  }

  public calcAlarmTooltipAnchor(
    $el: Element,
    timelinePoint: ITimelineDataPoint | null,
    width: number,
    height: number,
    margin: HubblePlotMargin
  ): HubblePlotTooltipAnchor {
    if (!timelinePoint) {
      // no hover point!
      return {
        x: 0,
        y: 0
      };
    }
    // get the target position
    const box = $el;
    const boxSize = box.getBoundingClientRect();
    //const graphTop = boxSize.top + margin.top;
    //const graphBottom = boxSize.bottom - margin.bottom;

    return {
      x: boxSize.right - width + timelinePoint.x + margin.left,
      // y: (graphTop + graphBottom) / 2
      y: boxSize.bottom - height + timelinePoint.y + margin.top
    }
  }

  
  public findClosestPoint(
    offsetX: number,
    data: PlotDataPoint[],
    margin: HubblePlotMargin,
    scaleX: d3.ScaleLinear<number, number>
  ): IClosestPoint | null {
    const x = offsetX - margin.left;
    return data
      .map((point, index) => {
        const pointX = scaleX(point.timeMs);
        const closestPoint: IClosestPoint = {
          x: pointX,
          diff: Math.abs(pointX - x),
          index: index,
          point: point
        }
        return closestPoint;
      })
      .reduce((memo, val) => (memo.diff < val.diff ? memo : val));
  }

  public findOverlappingAlarm(
    offsetX: number,
    offsetY: number,
    timelineData: ITimelineDataPoint[],
    margin: HubblePlotMargin,
    alarmIconSize: number
  ): ITimelineDataPoint | null {
    const x = offsetX - margin.left;
    const y = offsetY - margin.top;
    const foundDataPoint = timelineData.find((timelineItem) => {
      const left = timelineItem.x - (alarmIconSize / 2);
      const right = timelineItem.x + (alarmIconSize / 2);
      const top = timelineItem.y - (alarmIconSize / 2);
      const bottom = timelineItem.y + (alarmIconSize / 2);
      return x >= left && x <= right && y >= top && y <= bottom;
    });
    return foundDataPoint || null;
  }

  public findOverlappingUserAnnotation(
    offsetX: number,
    offsetY: number,
    timelineData: ITimelineDataPoint[],
    margin: HubblePlotMargin,
    userAnnotationIconSize: number
  ): ITimelineDataPoint | null {
    const x = offsetX - margin.left;
    const y = offsetY - margin.top;
    const foundDataPoint = timelineData.find((timelineItem) => {
      if (timelineItem.timelineItem.type !== TimelineItemType.userAnnotation) {
        return false;
      }
      const left = timelineItem.x - (userAnnotationIconSize / 2);
      const right = timelineItem.x + (userAnnotationIconSize / 2);
      const top = timelineItem.y - (userAnnotationIconSize / 2);
      const bottom = timelineItem.y + (userAnnotationIconSize / 2);
      return x >= left && x <= right && y >= top && y <= bottom;
    });
    return foundDataPoint || null;
  }

  public getEstimatedTimeMs(
    offsetX: number,
    margin: HubblePlotMargin,
    scaleX: d3.ScaleLinear<number, number>
  ): number {
    const x = offsetX - margin.left;
    let result = Math.round(scaleX.invert(x));
    const domain = scaleX.domain();
    if (domain.length > 0) {
      const minValue = domain[0];
      const maxValue = domain[domain.length - 1];
      result = Math.max(result, minValue);
      result = Math.min(result, maxValue);
    }
    return result;
  }
}