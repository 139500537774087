
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { AllLabels } from '@/interfaces';
import { DateWrapper, graphElapsedTimeFormat } from '@/utils';
import { labelsStore, settingsStore } from '@/store';
import { ITimelineDataPoint } from './data';

@Component({
})
export default class HubblePlotAlarmTooltip extends Vue {
  
  public offset: number = 10;
  public positionX: string = '0px';
  public positionY: string = '0px';

  @Prop({required: true})
  public point!: ITimelineDataPoint;

  @Prop({required: true})
  public startTime!: DateWrapper;

  @Prop({required: true})
  public anchorX!: number;

  @Prop({required: true})
  public anchorY!: number;

  @Watch("anchorX")
  public onAnchorXUpdate(): void {
    this._updatePositionX();
  }

  @Watch("anchorY")
  public onAnchorYUpdate(): void {
    this._updatePositionY();
  }

  public mounted(): void {
    this._updatePositionX();
    this._updatePositionY();
  }

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get time(): string {
    const relativeTime = graphElapsedTimeFormat(this.point.timeMs);
    const absoluteTime = this.startTime.plusMilliseconds(this.point.timeMs).toDisplayDateTime(settingsStore.use12HourClock);
    return `${relativeTime} (${absoluteTime})`;
  }

  public get text(): string {
    return this.point.timelineItem.message;
  }

  private _updatePositionX(): void {
    const width = this.$el?.getBoundingClientRect().width ?? 0;
    let left = this.anchorX + this.offset;
    let right = left + width;
    if (window.innerWidth - right < 20) {
      // not enough space on the right of the hover, put it on the left
      left = this.anchorX - width - this.offset;
    }
    this.positionX = left + 'px';
  }

  private _updatePositionY(): void {
    const height = this.$el?.getBoundingClientRect().height ?? 0;
    const top = this.anchorY - height / 2;
    this.positionY = top + 'px';
  }
}

