
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import CaseFieldItem from '@/components/ui/CaseFieldItem.vue';
import { DateWrapper, caseDataProviderKey, formatDuration } from '@/utils';
import { AllLabels, CaseData, QuestionnaireResult } from '@/interfaces';
import { labelsStore, questionnaireStore, settingsStore } from '@/store';

interface DisplayItem {
  heading: string;
  value: string;
}

@Component({
  components: {
    CaseFieldItem,
  }
})
export default class SystemInfo extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get questionnaire(): QuestionnaireResult | undefined {
    return questionnaireStore.getQuestionnaireResult(this.caseData);
  }

  public get displayItems(): DisplayItem[] {
    return this.allDisplayItems.slice(0, 4);
  }

  public get allDisplayItems(): DisplayItem[] {
    const result: DisplayItem[] = [];
    // system serial number
    result.push({
      heading: this.labels.temp.casesSystemInfoDeviceSerialNumber,
      value: this.caseData.deviceSerialNumber
    });
    // system type
    result.push({
      heading: this.labels.temp.casesSystemInfoDeviceType,
      value: this.caseData.deviceType
    });
    // pump hours if we have it
    if (this.caseData.pumpHours >= 0) {
      result.push({
        heading: this.labels.temp.casesSystemInfoPumpHours,
        value: Math.floor(this.caseData.pumpHours).toString()
      });
    }
    // arrival time if we have it
    if (this.hasHospitalArrivalToStartCoolingValue) {
      result.push({
        heading: this.labels.temp.casesSystemInfoHospitalArrivalToStartCooling,
        value: formatDuration(this.hospitalArrivalTimeDeltaMs)
      });
    }
    // case start time
    result.push({
      heading: this.labels.temp.casesSystemInfoCaseDateTime,
      value: this.caseData.caseDateTime.toDisplayDateTime(settingsStore.use12HourClock)
    });
    return result;
  }

  public get hasHospitalArrivalToStartCoolingValue(): boolean {
    return this.hospitalArrivalTimeDeltaMs > 0 && !isNaN(this.hospitalArrivalTimeDeltaMs);
  }

  public get hospitalArrivalTimeDeltaMs(): number {
    const hospitalArrivalTime = this.questionnaire?.hospitalArrivalTime;
    if (!hospitalArrivalTime) {
      return NaN;
    }
    const hospitalArrivalTimeDate = new DateWrapper(hospitalArrivalTime);
    const delta = this.caseData.caseDateTime.minus(hospitalArrivalTimeDate);
    return delta;
  }  
}
