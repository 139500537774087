
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { PlotDataPoint } from '@/interfaces';

@Component({
})
export default class HubblePlotXAxisScaleControl extends Vue {

  @Prop({required: true})
  public data!: PlotDataPoint[];

  public targetRange: [number, number] | null = null;

  @Emit()
  public input(range: [number, number] | null): [number, number] | null {
    return range;
  }

  public get minValue(): number {
    return this.range.min;
  }

  public get maxValue(): number {
    return this.range.max;
  }

  public get range(): {min: number, max: number} {
    const range = { min: 0, max: 0};
    if (this.data.length > 0) {
      range.min = this.data[0].timeMs;
      range.max = this.data[this.data.length - 1].timeMs;
    }
    // check if the custom range is outside the bounds
    const customRange = this.targetRange;
    if (customRange) {
      if (customRange[0] < range.min || customRange[1] > range.max) {
        // outside the range, clear it
        this.setRangeValue(null);
      }
    }
    return range;
  }
  
  public setRangeValue(newRange: [number, number] | null) {
    if (newRange == null || newRange[0] === this.minValue && newRange[1] === this.maxValue) {
      this.targetRange = null;
    } else {
      this.targetRange = newRange;
    }
    this.input(this.targetRange);
  }

  public get displayValue(): [number, number] {
    const range = this.targetRange;
    if (range == null) {
      return [this.minValue, this.maxValue];
    } else {
      return range;
    }
  }
}

