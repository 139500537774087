import { render, staticRenderFns } from "./SizeObserver.vue?vue&type=template&id=43630ce0&scoped=true"
import script from "./SizeObserver.vue?vue&type=script&lang=ts"
export * from "./SizeObserver.vue?vue&type=script&lang=ts"
import style0 from "./SizeObserver.vue?vue&type=style&index=0&id=43630ce0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43630ce0",
  null
  
)

export default component.exports