
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlotDataPoint, TimelineItem } from '@/interfaces';
import SizeObserver from '@/hubble/SizeObserver.vue';
import { settingsStore } from '@/store';
import { DateWrapper, graphElapsedTimeFormat } from '@/utils';
import { CaseMode } from '@/enums';
import { HubblePlotMarginCalculator } from './util';
import { HubblePlotRenderOptions } from './data';
import HubblePlotXAxisScaleControl from './HubblePlotXAxisScaleControl.vue';
import HubblePlotXAxisScaleControlNew from './HubblePlotXAxisScaleControlNew.vue';
import HubblePlotSvg from './HubblePlotSvg.vue';

const hubblePlotMarginCalculator = new HubblePlotMarginCalculator();

@Component({
  components: {
    SizeObserver,
    HubblePlotSvg,
    HubblePlotXAxisScaleControl,
    HubblePlotXAxisScaleControlNew
  }
})
export default class HubblePlot extends Vue {

  @Prop({required: true})
  public isThermogard!: boolean;

  @Prop({required: true})
  public isBrainCool!: boolean;

  @Prop({required: true})
  public caseMode!: CaseMode;
  
  @Prop({required: true})
  public data!: PlotDataPoint[];

  @Prop({required: true})
  public minPatientTemperature!: number;

  @Prop({required: true})
  public maxPatientTemperature!: number;

  @Prop({required: true})
  public startTime!: DateWrapper;

  @Prop({default: false})
  public isFirst!: boolean;

  @Prop({default: false})
  public isLast!: boolean;

  @Prop({default: -1})
  public index!: number;

  @Prop({required: true})
  public timelineItems!: TimelineItem[];

  @Prop({default: ""})
  public title!: string;

  @Emit()
  public xAxisZoomChanged(): void {
  }

  public width: number = 0;
  public height: number = 0;
  public customTimeRange: [number, number] | null = null;

  @Watch('customTimeRange', {immediate: true})
  public onCustomTimeRangeChange(): void {
    this.xAxisZoomChanged();
  }

  public get displayTitle(): string {
    return `${this.title} (${this.totalTime})`;
  }

  public get totalTime(): string {
    let totalTimeMs = 0;
    if (this.data.length > 0) {
      totalTimeMs = this.data[this.data.length - 1].timeMs - this.data[0].timeMs;
    }
    return graphElapsedTimeFormat(totalTimeMs); 
  }

  public onSizeChanged(data: { width: number, height: number}): void {
    this.width = data.width;
    this.height = data.height;
  }

  public get margin() {
    return hubblePlotMarginCalculator.calcMargin(
      this.renderOptions
    );
  }

  public get hasXAxisZoom(): boolean {
    return this.customTimeRange != null;
  }

  public cancelZoom(): void {
    this.customTimeRange = null;
  }

  public get renderOptions(): HubblePlotRenderOptions {
    return new HubblePlotRenderOptions(
      18,
      18,
      this.index,
      this.isFirst,
      this.isLast,
      (this.isThermogard || this.isBrainCool) ? settingsStore.showAlarms : false,
      settingsStore.showBathTemperature,
      (this.isThermogard) ? settingsStore.showMachinePower : false,
      settingsStore.showNotes,
      settingsStore.showPatientTemperature,
      settingsStore.showSinglePhase,
      settingsStore.showState,
      settingsStore.showTargetTemperature,
      settingsStore.multiColorPatientTemperature,
      settingsStore.customPatientTemperatureRange,
      settingsStore.tallerGraph,
      settingsStore.useBathTemperatureRange,
      this.customTimeRange,
      this.height - 30,
      this.width,
      settingsStore.tooltipEnabled,
      settingsStore.tooltipRight,
      this.caseMode,
      this.minPatientTemperature,
      this.maxPatientTemperature,
      this.isThermogard,
      this.isBrainCool,
      this.startTime,
      false
    );
  }
}

