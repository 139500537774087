
import { TimeWithinRangePrecision } from '@/enums';
import { CaseData } from '@/interfaces';
import { caseDataProviderKey } from '@/utils';
import { Component, InjectReactive, Vue } from 'vue-property-decorator';

interface RangeOption {
  value: TimeWithinRangePrecision;
  text: string;
}

@Component({
})
export default class CaseKeyMetricsTimeWithinRange extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public rangeOptions: RangeOption[] = [
    {
      value: TimeWithinRangePrecision.Precision20,
      text: '± 2.0°C'
    },
    {
      value: TimeWithinRangePrecision.Precision10,
      text: '± 1.0°C'
    },
    {
      value: TimeWithinRangePrecision.Precision05,
      text: '± 0.5°C'
    }
  ];

  public selectedRangeOption: RangeOption = this.rangeOptions[0];

  get timeWithinTargetRangeString(): string {
    return this.timeWithinTargetRangePercent.toFixed(1) + '%';
  }

  get timeWithinTargetRangePercent(): number {
    switch (this.selectedRangeOption.value) {
      case TimeWithinRangePrecision.Precision02:
        return this.caseData.timeWithinRange02.toPercentNumber();
      case TimeWithinRangePrecision.Precision05:
        return this.caseData.timeWithinRange05.toPercentNumber();
      case TimeWithinRangePrecision.Precision10:
        return this.caseData.timeWithinRange10.toPercentNumber();
      case TimeWithinRangePrecision.Precision20:
        return this.caseData.timeWithinRange20.toPercentNumber();
      default:
        return 0;
    }
  }

  get normalizedRadius(): number {
    return this.radius - this.strokeWidth / 2;
  }

  get strokeDashoffset(): number {
    return this.circumference - (this.timeWithinTargetRangePercent / 100) * this.circumference;
  }

  get circumference(): number {
    return this.normalizedRadius * 2 * Math.PI;
  }

  get radius(): number {
    return 65;
  }

  get strokeWidth(): number {
    return 8;
  }
}
