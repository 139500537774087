
import { AllLabels } from '@/interfaces';
import { settingsStore, labelsStore } from '@/store';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
})
export default class HubblePlotLegend extends Vue {
  @Prop({ default: false })
  public horizontal!: boolean;

  @Prop({ required: true })
  public isThermogard!: boolean;

  @Prop({ required: true })
  public isBrainCool!: boolean;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get multiColorPatientTemperature(): boolean {
    return settingsStore.multiColorPatientTemperature;
  }
}

