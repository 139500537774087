
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NavItem } from './interfaces';

@Component({
  components: {
    VNode: {
      functional: true,
      render: (h: any, data: { props: any }) => {
        const props = data.props;
        return props.node ? props.node : h("span", props.name);
      },
    },
  }
})
export default class TabNav extends Vue {
  
  @Prop({ required: true})
  public navItems!: NavItem[];

  @Prop({ required: true})
  public tabItemActive!: NavItem;

  @Emit()
  public select(navItem: NavItem): NavItem {
    return navItem;
  }
}
