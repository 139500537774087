
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AppButton from './AppButton.vue';

@Component({
  components: {
    AppButton
  }
})
export default class AppAlertDialog extends Vue {

    @Prop({ required: true })
    public dialog!: boolean;

    @Prop({ required: true })
    public message!: string;

    @Prop({ required: true })
    public buttonText!: string;

    @Prop({ default: false })
    public disabled!: boolean;

    @Emit()
    public buttonClick(): void {
    }
}

