import { CaseMode } from "@/enums";
import { DateWrapper } from "@/utils";

export class HubblePlotRenderOptions {

  constructor(
    public alarmSize: number,
    public userAnnotationSize: number,
    public index: number,
    public isFirst: boolean,
    public isLast: boolean,
    public showAlarms: boolean,
    public showBathTemperature: boolean,
    public showMachinePower: boolean,
    public showNotes: boolean,
    public showPatientTemperature: boolean,
    public showSinglePhase: boolean,
    public showState: boolean,
    public showTargetTemperature: boolean,
    public multiColorPatientTemperature: boolean,
    public customPatientTemperatureRange: [number, number] | null,
    public tallerGraph: boolean,
    public useBathTemperatureRange: boolean,
    public customTimeRange: [number, number] | null,
    public height: number,
    public width: number,
    public tooltipEnabled: boolean,
    public tooltipRight: boolean,
    public caseMode: CaseMode,
    public minPatientTemperature: number,
    public maxPatientTemperature: number,
    public isThermogard: boolean,
    public isBrainCool: boolean,
    public startTime: DateWrapper,
    public isZoomPreview: boolean
  ) {
  }

  public get isShowingTemperature(): boolean {
    return this.showTargetTemperature
      || this.showPatientTemperature;
  }
}