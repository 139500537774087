
import { Component, Prop, Vue } from 'vue-property-decorator';

const noValue = '---';

@Component
export default class CaseFieldItem extends Vue {
  @Prop({ required: true})
  public heading!: string;

  @Prop({ required: true })
  public value!: string;

  @Prop({ default: false })
  public long!: boolean;

  public get displayHeading(): string {
    return this.heading || noValue;
  }

  public get displayValue(): string {
    return this.value || noValue;
  }
}
