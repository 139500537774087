
import { Component, Emit, Vue } from 'vue-property-decorator';

@Component({})
export default class SizeObserver extends Vue {

  public width!: number;
  public height!: number;

  private intervalHandle!: any;

  @Emit()
  public sizeChanged(): { width: number, height: number } {
    return { width: this.width, height: this.height };
  }

  public mounted(): void {
    const box = this.$el;
    const boxSize = box.getBoundingClientRect();
    this.width = boxSize.width;
    this.height = boxSize.height;
    this.sizeChanged();

    this.intervalHandle = setInterval(this.checkSizeChanged, 100);
    window.addEventListener('resize', this.checkSizeChanged);
  }

  public beforeDestroy(): void {
    window.removeEventListener('resize', this.checkSizeChanged);
    clearInterval(this.intervalHandle);
  }

  private checkSizeChanged(): void {
    const box = this.$el;
    const boxSize = box.getBoundingClientRect();
    let changed: boolean = false;
    if (boxSize.width != this.width) {
      changed = true;
      this.width = boxSize.width;
    }
    if (boxSize.height != this.height) {
      changed = true;
      this.height = boxSize.height;
    }
    if (changed){
      this.sizeChanged();
    }
  }
}
