
import { AllLabels } from '@/interfaces';
import { demoStore, labelsStore, uploadStore } from '@/store';
import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/ui/AppButton.vue';
import CaseInsights from './CaseInsights/CaseInsights.vue';
import CaseKeyMetrics from '@/hubble/CaseKeyMetrics/CaseKeyMetrics.vue';
import CaseGraph from '@/hubble/CaseGraph/CaseGraph.vue';
import CaseTertiaryMetrics from '@/hubble/CaseTertiaryMetrics/CaseTertiaryMetrics.vue';
import { ExportGraphEvent, demoService, eventService } from '@/services';
import HorizontalScrollContainer from '@/components/ui/HorizontalScrollContainer.vue';
import { DemoType } from '@/enums';
import { isThermogardDevice } from '@/utils';

@Component({
  components: {
    AppButton,
    CaseInsights,
    CaseKeyMetrics,
    CaseGraph,
    CaseTertiaryMetrics,
    HorizontalScrollContainer
  }
})
export default class ResultsPageContent extends Vue {

  public demoSystemTypeOptions: SystemOption[] = [
    { demoType: DemoType.TGXP },
    { demoType: DemoType.BrainCool }
  ];

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public onViewResultsClicked(): void {
    uploadStore.showResultsPage();
  }

  public get isDemoActive(): boolean {
    return demoStore.isDemoActive;
  }

  public get canExportCsv(): boolean {
    if (demoStore.isDemoActive) {
      return false;
    }
    if (!uploadStore.selectedCase) {
      // should not happen
      return false;
    }
    if (!isThermogardDevice(uploadStore.selectedCase)) {
      return false;
    }

    return true;
  }

  public get canExportPng(): boolean {
    return true;
  }

  public get demoSystemType(): SystemOption {
    let result = this.demoSystemTypeOptions.find((x) => x.demoType === demoStore.demoType);
    if (!result) {
      result = this.demoSystemTypeOptions[0];
    }
    return result;
  }

  public getDemoTypeText(systemOption: SystemOption): string {
    switch (systemOption.demoType) {
      case DemoType.TGXP:
        return 'Thermogard';
      case DemoType.BrainCool:
        return 'BrainCool / IQool';
      default:
        return 'Unknown';
    }
  }

  public getDemoTypeValue(systemOption: SystemOption): DemoType {
    return systemOption.demoType;
  }

  public setDemoSystemType(demoType: DemoType): void {
    demoService.changeDemoType(demoType);
  }

  public back(): void {
    if (this.isDemoActive) {
      this.$router.push({name: 'Home'});
    } else {
      uploadStore.showSelectCasePage();
    }
  }

  public exportClicked(csv: boolean): void {
    eventService.publish(new ExportGraphEvent(csv));
  }
}

interface SystemOption {
  demoType: DemoType;
}

