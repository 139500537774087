
import { Component, Prop, Vue } from 'vue-property-decorator';
import Tabs from './Tabs.vue';

@Component({
  name: 'TabItem'
})
export default class TabItem extends Vue {
  @Prop({default: 'Tab Item'})
  public name!: string;

  public activeModel: string = '';
  public model: string = Math.random().toString(16).substring(2);

  public get isActive(): boolean {
    return this.activeModel === this.model;
  }

  public get parentTabs(): Tabs {
    return this.$parent as Tabs;
  }

  public get ownNavItemIndex(): number {
    return this.parentTabs.navItems.findIndex((navItem) => navItem.model === this.model);
  }

  mounted() {
    this.parentTabs.register(this);
  }

  beforeDestroy() {
    this.parentTabs.unregister(this);
  }

  public beforeEnterHandle(element: any) {
    element.style.position = 'relative';
  }

  public leaveHandle(element: any) {
    element.style.position = 'absolute';
  }
}
