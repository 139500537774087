
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { PlotDataPoint } from '@/interfaces';
import { HubblePlotRenderOptions } from './data';
import HubblePlotSlider from './HubblePlotSlider.vue';
import HubblePlotSvg from './HubblePlotSvg.vue';

@Component({
  components: {
    HubblePlotSlider,
    HubblePlotSvg
  }
})
export default class HubblePlotXAxisScaleControlNew extends Vue {

  @Prop({required: true})
  public data!: PlotDataPoint[];

  @Prop({required: true})
  public renderOptions!: HubblePlotRenderOptions;

  @Prop({required: true})
  public width!: number;

  @Prop({ required: true })
  public value!: [number, number] | null;

  @Emit()
  public input(range: [number, number] | null): [number, number] | null {
    return range;
  }

  public targetRange: [number, number] | null = null;

  @Watch('value', {immediate: true})
  public onValueChanged(): void {
    this.setRangeValue(this.value);
  }

  public get minValue(): number {
    return this.range.min;
  }

  public get maxValue(): number {
    return this.range.max;
  }

  public get range(): {min: number, max: number} {
    const range = { min: 0, max: 0};
    if (this.data.length > 0) {
      range.min = this.data[0].timeMs;
      range.max = this.data[this.data.length - 1].timeMs;
    }
    // check if the custom range is outside the bounds
    const customRange = this.targetRange;
    if (customRange) {
      if (customRange[0] < range.min || customRange[1] > range.max) {
        // outside the range, clear it
        this.setRangeValue(null);
      }
    }
    return range;
  }
  
  public setRangeValue(newRange: [number, number] | null) {
    if (newRange == null || newRange[0] === this.minValue && newRange[1] === this.maxValue) {
      this.targetRange = null;
    } else {
      this.targetRange = newRange;
    }
    this.input(this.targetRange);
  }

  public get displayValue(): [number, number] {
    const range = this.targetRange;
    if (range == null) {
      return [this.minValue, this.maxValue];
    } else {
      return range;
    }
  }

  public get zoomRenderOptions(): HubblePlotRenderOptions {
    const result = new HubblePlotRenderOptions(
      this.renderOptions.alarmSize,
      this.renderOptions.userAnnotationSize,
      this.renderOptions.index,
      this.renderOptions.isFirst,
      this.renderOptions.isLast,
      false, // override showAlarms
      this.renderOptions.showBathTemperature,
      this.renderOptions.showMachinePower,
      this.renderOptions.showNotes,
      this.renderOptions.showPatientTemperature,
      this.renderOptions.showSinglePhase,
      this.renderOptions.showState,
      this.renderOptions.showTargetTemperature,
      this.renderOptions.multiColorPatientTemperature,
      this.renderOptions.customPatientTemperatureRange,
      this.renderOptions.tallerGraph,
      this.renderOptions.useBathTemperatureRange,
      null, // override customTimeRange,
      30, // override height
      this.width, // override width
      false, // override tooltipEnabled
      this.renderOptions.tooltipRight,
      this.renderOptions.caseMode,
      this.renderOptions.minPatientTemperature,
      this.renderOptions.maxPatientTemperature,
      this.renderOptions.isThermogard,
      this.renderOptions.isBrainCool,
      this.renderOptions.startTime,
      true // override isZoomPreview
    );
    return result;
  }
}

