import { CaseMode, TimelineItemType } from '@/enums';
import { PlotDataPoint, TimelineItem } from '@/interfaces';
import { labelsStore } from '@/store';
import { graphElapsedTimeFormat } from '@/utils';
import * as d3 from 'd3';
import { HubblePlotMargin, HubblePlotRenderOptions, HubblePlotRenderResult, IDataPoint } from '../data';
import { HubblePlotCalculator } from './HubblePlotCalculator';
import { HubblePlotMarginCalculator } from './HubblePlotMarginCalculator';
import { HubblePlotPointsCalculator } from './HubblePlotPointsCalculator';

const hubblePlotCalculator = new HubblePlotCalculator();
const hubblePlotPointsCalculator = new HubblePlotPointsCalculator();
const hubblePlotMarginCalculator = new HubblePlotMarginCalculator();

export class HubblePlotRenderer {

  constructor() {
    
  }

  public render(
    $el: Element,
    data: PlotDataPoint[],
    timelineItems: TimelineItem[],
    graphWidth: number,
    graphHeight: number,
    margin: HubblePlotMargin,
    caseMode: CaseMode,
    minPatientTemperature: number,
    maxPatientTemperature: number,
    scaleX: d3.ScaleLinear<number, number>,
    options: HubblePlotRenderOptions
  ): HubblePlotRenderResult {

    const clipIdentifier = options.index + (options.isZoomPreview ? '-zoom': '');

    const svgElem = $el.querySelector('svg');
    const svg = d3.select(svgElem);

    svg.selectAll(":not(.hover-line)").remove();

    if (graphWidth <= 0 || graphHeight <= 0) {
      // we are too small to graph, don't try
      return { 
        timelinePoints: []
      };
    }
    
    // need to insert before the hover container so we draw the hover line on top of the svg
    const g = svg.insert('g', '.hover-line--container')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const patientTemperatureRange = hubblePlotCalculator.calcTemperatureRange(
      caseMode,
      options.showSinglePhase,
      minPatientTemperature,
      maxPatientTemperature,
      options.customPatientTemperatureRange,
      options.useBathTemperatureRange,
      options.showBathTemperature
    );
    const bathTemperatureRange = hubblePlotCalculator.calcBathTemperatureRange(
      options.useBathTemperatureRange,
      options.customPatientTemperatureRange
    );

    const patientTemperatureScaleY = d3
      .scaleLinear()
      .range([graphHeight, 0])
      .domain([patientTemperatureRange.min, patientTemperatureRange.max]);

    // 0C to 38C is the range of the bath temp
    const bathTemperatureScaleY = d3
      .scaleLinear()
      .range([graphHeight, 0])
      .domain([bathTemperatureRange.min, bathTemperatureRange.max]);
    // -100% to 100% is the range
    const machinePowerScaleY = d3
      .scaleLinear()
      .range([graphHeight, 0])
      .domain([-100, 100]);

    const timeTicks = hubblePlotCalculator.calcTimeTicks(
      data,
      graphHeight,
      scaleX
    );

    const targetTemperaturePoints = hubblePlotPointsCalculator.calcTargetTemperaturePoints(
      data,
      scaleX,
      patientTemperatureScaleY
    );

    const patientTemperaturePoints = hubblePlotPointsCalculator.calcPatientTemperaturePoints(
      data,
      scaleX,
      patientTemperatureScaleY
    );

    const timelinePoints = hubblePlotPointsCalculator.calcTimelinePoints(
      data,
      timelineItems,
      scaleX,
      patientTemperatureScaleY
    );

    const statePoints = hubblePlotPointsCalculator.calcStatePoints(
      data,
      scaleX,
      machinePowerScaleY
    );

    const bathTemperaturePoints = hubblePlotPointsCalculator.calcBathTemperaturePoints(
      data,
      scaleX,
      bathTemperatureScaleY
    );

    const powerAreaPoints = hubblePlotPointsCalculator.calcPowerPoints(
      data,
      scaleX,
      machinePowerScaleY
    );

    const conditionalLineDefinition = d3
      .line<IDataPoint>()
      .x(d => d.x)
      .y(d => d.y)
      .defined(d => d.defined);
    
    const powerAreaDefinition = d3
      .area<IDataPoint>()
      .x(d => d.x)
      .y0(d => d.y)
      .y1(() => graphHeight / 2)
      .defined(d => d.defined);
      
    const stateAreaDefinition = d3
      .area<IDataPoint>()
      .x(d => d.x)
      .y0(() => graphHeight)
      .y1(() => 0)
      .defined(d => d.defined);

    let xDomain = scaleX.domain();
    if (!options.isLast) {
      // only the last plot gets the end time
      xDomain = [xDomain[0]];
    }
    if (xDomain.length > 1 && xDomain[0] == xDomain[1]) {
      // the domain is the same (we only have one data point)
      // only draw one of them
      xDomain = [xDomain[0]];
    }

    g.append('g')
      .attr('transform', 'translate(0,' + graphHeight + ')')
      .classed('x-axis', true)
      .call(
        d3.axisBottom(scaleX)
          .tickValues(xDomain)
          .tickSizeInner(0)
          .tickFormat(graphElapsedTimeFormat)
      )
      .call(g => g.select(".domain").remove());

    if (options.isFirst && options.isShowingTemperature) {
      g.append('g')
      .call(
        d3.axisLeft(patientTemperatureScaleY)
          .tickSizeInner(0)
          .tickValues(patientTemperatureRange.ticks)
          .tickFormat((d: d3.NumberValue) => {
            return d3.format('')(d) + '°C';
          })
      );
      g.append('g')
        .classed('y-axis-label', true)
        .append('text')
        .attr('transform', 'translate(' + 0 + ',-10)')
        .attr('text-anchor', 'end')
        .text(labelsStore.labels.temp.casesPlotAxisPatientTemperature);
    }

    if (options.isLast && options.showBathTemperature && !options.useBathTemperatureRange) {
      g.append('g')
      .attr('transform', 'translate(' + graphWidth + ',0)')
      .call(
        d3.axisRight(bathTemperatureScaleY)
          .tickSizeInner(0)
          .tickValues(bathTemperatureRange.ticks)
          .tickFormat((d: d3.NumberValue) => {
            return d3.format('')(d) + '°C';
          })
      );
      g.append('g')
        .classed('y-axis-label', true)
        .append('text')
        .attr('transform', 'translate(' + (graphWidth + 3) + ',-10)')
        .text(labelsStore.labels.temp.casesPlotAxisBathTemperature);
    }

    if (options.isLast && options.showMachinePower) {
      const xOffset = hubblePlotMarginCalculator.getMachinePowerAxisXOffset(options);
      g.append('g')
      .attr('transform', 'translate(' + (graphWidth + xOffset) + ',0)')
      .call(
        d3.axisRight(machinePowerScaleY)
          .tickSizeInner(0)
          .tickValues([-100, 0, 100])
          .tickFormat((d: d3.NumberValue) => {
            return d3.format('')(Math.abs(+d)) + '%';
          })
      );
      g.append('text')
        .classed('y-axis-label', true)
        .attr('transform', 'translate(' + (graphWidth + xOffset + 3) + ',-10)')
        .text(labelsStore.labels.temp.casesPlotAxisMachinePower);
    }

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'hubble-plot-clip-' + clipIdentifier)
      .append('rect')      
      .attr('width', graphWidth)
      .attr('height', graphHeight);

    const innerG = g.append('g')
      .attr("clip-path", `url(#hubble-plot-clip-${clipIdentifier})`);

    innerG.append('g')
      .append('rect')
      .attr('class', 'graph-background')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', graphWidth)
      .attr('height', graphHeight);

    if (options.showMachinePower) {
      innerG.append('path')
        .datum(powerAreaPoints.negative)
        .classed('negativePower', true)
        .attr('d', powerAreaDefinition);
      
      innerG.append('path')
        .datum(powerAreaPoints.positive)
        .classed('positivePower', true)
        .attr('d', powerAreaDefinition);
    }

    if (options.showBathTemperature) {
      innerG.append('path')
        .datum(bathTemperaturePoints)
        .classed('bathTemperature', true)
        .attr('d', conditionalLineDefinition);
    }

    if (options.showTargetTemperature) {
      innerG.append('path')
        .datum(targetTemperaturePoints)
        .classed('targetTemperature', true)
        .attr('d', conditionalLineDefinition);
    }
    
    if (options.showPatientTemperature) {
      const patientTemperatureClassSuffix = options.multiColorPatientTemperature ? '' : ' singleColor';
      innerG.append('path')
        .datum(patientTemperaturePoints.normal1)
        .classed('patientTemperatureNormal' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);
        innerG.append('path')
        .datum(patientTemperaturePoints.normal2)
        .classed('patientTemperatureNormal' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);

      innerG.append('path')
        .datum(patientTemperaturePoints.warning)
        .classed('patientTemperatureWarning' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);
      
        innerG.append('path')
        .datum(patientTemperaturePoints.danger1)
        .classed('patientTemperatureDanger' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);
        innerG.append('path')
        .datum(patientTemperaturePoints.danger2)
        .classed('patientTemperatureDanger' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);

      /* don't show invalid temperature lines
      innerG.append('path')
        .datum(patientTemperaturePoints.invalid)
        .classed('patientTemperatureInvalid' + patientTemperatureClassSuffix, true)
        .attr('d', conditionalLineDefinition);*/
    }

    // always render the selected timelinePoints
    // they are filtered out if they should not be displayed
    const nonInfoTypes = [TimelineItemType.alarm, TimelineItemType.userAnnotation];
    innerG.selectAll(".timelinePoint")
      .data(timelinePoints)
      .enter().call((selection) => {
        const alarmG = selection.append('g')
          .attr('transform', (d) => {
            return 'translate(' + d.x + ',' + d.y + ')'
          })
          .classed('timelinePoint', true)
          .append('g')
          .classed('timelinePoint-content', true);
        alarmG
          .filter((d) => d.timelineItem.type === TimelineItemType.alarm)
          .append('path')
          .attr('d', (_d) => {
            const size = options.alarmSize;
              return d3.line()(
                [
                  [-size/2, size/2],
                  [0, -size/2],
                  [size/2, size/2]
                ]
              );
          });
        alarmG
          .filter((d) => d.timelineItem.type === TimelineItemType.userAnnotation)
          .append('path')
          .attr('d', (_d) => {
            const size = options.userAnnotationSize;
            return d3.line()(
              [
                [-size/2, -size/2],
                [size/2, -size/2],
                [size/2, size/2],
                [-size/2, size/2]
              ]
            );
          });
        alarmG
          .filter((d) => !nonInfoTypes.includes(d.timelineItem.type))
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', options.userAnnotationSize/2);
          
        alarmG.append('path')
          .classed('background', true)
          .attr('d', (_d) => {
            const size = options.alarmSize;
            return d3.line()(
              [
                [-size/2, size/2],
                [-size/2, -size/2],
                [size/2, -size/2],
                [size/2, size/2]
              ]
            );
        });
      });

    if (options.showState) {
      innerG.append('path')
        .datum(statePoints.standby)
        .classed('stateStandby', true)
        .attr('d', stateAreaDefinition);
      innerG.append('path')
        .datum(statePoints.off)
        .classed('stateOff', true)
        .attr('d', stateAreaDefinition);
    }

    if (!options.isZoomPreview) {
      innerG.append('g')
        .selectAll('.dot')
        .data(timeTicks)
        .enter()
        .append("rect")
          .attr("x", function (d) { return d.x; } )
          .attr("y", function (d) { return d.y - 4; } )
          .attr("width", 1)
          .attr("height", 4)
          .attr('class', 'time-tick');
    }

    return {
      timelinePoints: timelinePoints
    };
  }
}