import { PlotDataPoint, TimelineItem } from '@/interfaces';
import * as d3 from 'd3';
import { HubblePlotPowerPoints, HubblePlotPatientTemperaturePoints, HubblePlotStatePoints, IDataPoint, ITimelineDataPoint } from '../data';
import { PlotDataPointFactory } from './PlotDataPointFactory';
import { RangePlotDataPointFactory } from './RangePlotDataPointFactory';

export class HubblePlotPointsCalculator {
  constructor() {

  }

  public calcPowerPoints(
    data: PlotDataPoint[],
    scaleX: d3.ScaleLinear<number, number>,
    machinePowerScaleY: d3.ScaleLinear<number, number>
  ): HubblePlotPowerPoints {
    const negativePowerAreaPoints = new PlotDataPointFactory();
    const positivePowerAreaPoints = new PlotDataPointFactory();
    let previousPower = 0;
    let previousTimeMs = 0;
    let previousValid = false;
    const zeroY = machinePowerScaleY(0);
    data.forEach((item) => {
      const currentValid = item.power !== 0 || item.state === 'Run';
      const currentPower = item.power;
      if (!currentValid) {
        // no power
        positivePowerAreaPoints.add({
          timeMs: item.timeMs,
          defined: false,
          x: scaleX(item.timeMs),
          y: zeroY
        });
        negativePowerAreaPoints.add({
          timeMs: item.timeMs,
          defined: false,
          x: scaleX(item.timeMs),
          y: zeroY
        });
      } else {
        if (previousPower * currentPower < 0 && previousValid) {
          // transitioned from negative to positive (or vice-versa)
          const totalChange = currentPower - previousPower;
          const changeToTransition = -previousPower;
          const percent = (changeToTransition / totalChange);
          const transitionTimeMs = previousTimeMs + (item.timeMs - previousTimeMs) * percent;
          const x = scaleX(transitionTimeMs);
          positivePowerAreaPoints.add({
            timeMs: transitionTimeMs,
            defined: true,
            x: x,
            y: zeroY
          });
          negativePowerAreaPoints.add({
            timeMs: transitionTimeMs,
            defined: true,
            x: x,
            y: zeroY
          });
        }
        const x = scaleX(item.timeMs);
        const y = machinePowerScaleY(currentPower);
        if (currentPower > 0) {
          positivePowerAreaPoints.add({
            timeMs: item.timeMs,
            defined: true,
            x: x,
            y: y
          });
          // power is positive, don't graph the negative one
          negativePowerAreaPoints.add({
            timeMs: item.timeMs,
            defined: false,
            x: x,
            y: zeroY
          });
        }
        else {
          // power is negative, don't graph the positive one
          negativePowerAreaPoints.add({
            timeMs: item.timeMs,
            defined: true,
            x: x,
            y: y
          });
          // power is negative, don't graph the positive one
          positivePowerAreaPoints.add({
            timeMs: item.timeMs,
            defined: false,
            x: x,
            y: zeroY
          });
        }
      }
      previousValid = currentValid;
      previousPower = currentPower;
      previousTimeMs = item.timeMs;
    });
    return {
      positive: positivePowerAreaPoints.getData(),
      negative: negativePowerAreaPoints.getData(),
    };
  }

  public calcTimelinePoints(
    data: PlotDataPoint[],
    timelineItems: TimelineItem[],
    scaleX: d3.ScaleLinear<number, number>,
    temperatureScaleY: d3.ScaleLinear<number, number>
  ): ITimelineDataPoint[] {
    // data and timelineItems are sorted in ascending order

    // remove invalid patient temperature values
    data = data.filter((x) => !this._isInvalidPatientTemp(x));

    const result: ITimelineDataPoint[] = [];
    let dataIndex = 0;
    for (let i = 0; i < timelineItems.length; i++) {
      const timelineItem = timelineItems[i];
      // find data points on either side of the timeline item

      // increment until the next point is after the point we are looking for
      let shouldIncrement = false;

      do {

        if (dataIndex + 1 >= data.length) {
          // out of data
          shouldIncrement = false;
          break;
        }

        const nextDataPoint = data[dataIndex + 1];
        shouldIncrement = nextDataPoint.timeMs <= timelineItem.timeMs;

        if (shouldIncrement) {
          dataIndex++;
        }
      } while (shouldIncrement);

      const leftDataPoint = dataIndex < data.length ? data[dataIndex] : null;
      let rightDataPoint = (dataIndex + 1) < data.length ? data[dataIndex + 1] : null;
      if (!rightDataPoint && leftDataPoint?.timeMs === timelineItem.timeMs) {
        // there is no data point greater, but the left data point is at the exact time
        // set the right data point so we include the timeline event
        rightDataPoint = leftDataPoint;
      }

      if (!leftDataPoint || !rightDataPoint) {
        // out data, nothing else to add
        break;
      }

      if (leftDataPoint.timeMs > timelineItem.timeMs) {
        // timeline item is before the data we are looking at, don't add it
        continue;
      }

      const x1 = leftDataPoint.timeMs;
      const y1 = leftDataPoint.patientTemp;
      const x2 = rightDataPoint.timeMs;
      const y2 = rightDataPoint.patientTemp;
      const x = timelineItem.timeMs;

      let interpolatedPatientTemperature: number;
      if (x2 === x1) {
        // should only happen if timeline point at the time of the last data point
        // left and right data points will be the same, just use the first
        interpolatedPatientTemperature = y1;
      } else {
        interpolatedPatientTemperature = y1 + ((x - x1) / (x2 - x1)) * (y2 - y1);
      }

      result.push({
        timelineItem: timelineItem,
        defined: true,
        timeMs: timelineItem.timeMs,
        x: scaleX(timelineItem.timeMs),
        y: temperatureScaleY(interpolatedPatientTemperature)
      });
    }
    return result;
  }

  public calcPatientTemperaturePoints(
    data: PlotDataPoint[],
    scaleX: d3.ScaleLinear<number, number>,
    temperatureScaleY: d3.ScaleLinear<number, number>
  ): HubblePlotPatientTemperaturePoints {
    const patientTemperaturePointsDanger1 = new RangePlotDataPointFactory(null, 32, temperatureScaleY);
    const patientTemperaturePointsNormal1 = new RangePlotDataPointFactory(32, 34, temperatureScaleY);
    const patientTemperaturePointsWarning = new RangePlotDataPointFactory(34, 36, temperatureScaleY);
    const patientTemperaturePointsNormal2 = new RangePlotDataPointFactory(36, 38, temperatureScaleY);
    const patientTemperaturePointsDanger2 = new RangePlotDataPointFactory(38, null, temperatureScaleY);

    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const x = scaleX(item.timeMs);
      const timeMs = item.timeMs;
      const patientTemp = item.patientTemp;
      const valid = !this._isInvalidPatientTemp(item);
      patientTemperaturePointsDanger1.add(x, timeMs, patientTemp, valid);
      patientTemperaturePointsNormal1.add(x, timeMs, patientTemp, valid);
      patientTemperaturePointsWarning.add(x, timeMs, patientTemp, valid);
      patientTemperaturePointsNormal2.add(x, timeMs, patientTemp, valid);
      patientTemperaturePointsDanger2.add(x, timeMs, patientTemp, valid);
    }

    const result: HubblePlotPatientTemperaturePoints = {
      normal1: patientTemperaturePointsNormal1.getData(),
      normal2: patientTemperaturePointsNormal2.getData(),
      warning: patientTemperaturePointsWarning.getData(),
      danger1: patientTemperaturePointsDanger1.getData(),
      danger2: patientTemperaturePointsDanger2.getData(),
      invalid: []
    };
    return result;
  }

  public calcTargetTemperaturePoints(
    data: PlotDataPoint[],
    scaleX: d3.ScaleLinear<number, number>,
    temperatureScaleY: d3.ScaleLinear<number, number>
  ): IDataPoint[] {
    const targetTemperaturePoints = new PlotDataPointFactory();
    data.forEach((item) => {
      targetTemperaturePoints.add({
        timeMs: item.timeMs,
        x: scaleX(item.timeMs),
        y: temperatureScaleY(item.targetTemp),
        defined: item.targetTemp > 0 && item.state != 'Off'
      });
    });
    return targetTemperaturePoints.getData();
  }

  public calcBathTemperaturePoints(
    data: PlotDataPoint[],
    scaleX: d3.ScaleLinear<number, number>,
    bathTemperatureScaleY: d3.ScaleLinear<number, number>
  ): IDataPoint[] {
    const bathTemperaturePoints = new PlotDataPointFactory();
    data.forEach((item) => {
      bathTemperaturePoints.add({
        timeMs: item.timeMs,
        defined: true,
        x: scaleX(item.timeMs),
        y: bathTemperatureScaleY(item.bathTemp)
      });
    });
    return bathTemperaturePoints.getData();
  }

  public calcStatePoints(
    data: PlotDataPoint[],
    scaleX: d3.ScaleLinear<number, number>,
    machinePowerScaleY: d3.ScaleLinear<number, number>
  ): HubblePlotStatePoints {
    const statePointsRun = new PlotDataPointFactory();
    const statePointsStandby = new PlotDataPointFactory();
    const statePointsOff = new PlotDataPointFactory();
    let previousStatePoints: PlotDataPointFactory | null = null;
    const stateY = machinePowerScaleY(0);
    for (let i = 1; i < data.length; i++) {
      const previousItem = data[i - 1];
      const item = data[i];
      // use the previous time for the position
      // that way the area starts at the previous point
      const timeMs = previousItem.timeMs;
      const x = scaleX(timeMs);

      let currentStatePoints: PlotDataPointFactory | null = null;
      if (item.state === 'Run') {
        // add to the run points
        currentStatePoints = statePointsRun;
      } else if (item.state === 'Standby') {
        // add to the standby points
        currentStatePoints = statePointsStandby;
      } else if (item.state === 'Off') {
        // add to the Off
        currentStatePoints = statePointsOff;
      } else {
        // unknown? no points here
        currentStatePoints = null;
      }
      
      
      // always add the point, only display it if the current or previous point belongs to that list
      const definedPoints = [previousStatePoints, currentStatePoints];
      statePointsRun.add({
        timeMs: timeMs,
        x: x,
        y: stateY,
        defined: definedPoints.includes(statePointsRun)
      });
      statePointsStandby.add({
        timeMs: timeMs,
        x: x,
        y: stateY,
        defined: definedPoints.includes(statePointsStandby)
      });
      statePointsOff.add({
        x: x,
        timeMs: timeMs,
        y: stateY,
        defined: definedPoints.includes(statePointsOff)
      });

      previousStatePoints = currentStatePoints;
    }

    const result: HubblePlotStatePoints = {
      run: statePointsRun.getData(),
      standby: statePointsStandby.getData(),
      off: statePointsOff.getData(),
    };
    return result;
  }

  private _isInvalidPatientTemp(dataPoint: PlotDataPoint) {
    return dataPoint.patientTemp === 0 || dataPoint.patientTemp >= 50;
  }
}