
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { NavItem, TabItemIndexes } from './interfaces';
import TabItem from './TabItem.vue';
import TabNav from './TabNav.vue';

@Component({
  components: {
    TabNav
  }
})
export default class Tabs extends Vue {

  @Ref('tabs-content')
  public tabsContent: HTMLElement | undefined;

  public tabItemActive: NavItem = {
    model: '',
    name: '',
    nameSlot: undefined,
  }
  public tabItemIndexes: TabItemIndexes = {
    current: 0,
    last: 0,
    previous: 0
  };
  public navItems: NavItem[] = [];
  public tabItems: TabItem[] = [];

  @Prop({ default: false })
  public fullHeight!: boolean;

  @Watch('tabItemActive')
  public onTabItemActiveChanged(newTabItem: NavItem, oldTabItem: NavItem): void {
    this.tabItemIndexes.previous = this.findIndexTab(oldTabItem);
    this.tabItemIndexes.current = this.findIndexTab(newTabItem);
    this.tabItems[this.tabItemIndexes.current].activeModel = newTabItem.model;
    if (this.tabItemIndexes.previous > -1) {
      this.tabItems[this.tabItemIndexes.previous].activeModel = newTabItem.model;
    }
  }

  @Watch('value', {immediate: true})
  public onValueChanged(newValue: number = 0): void {
    this.$nextTick(() => {
      const navItem = this.navItems[newValue];
      if (navItem) {
        this.activeTabItem(navItem);
      }
    });
  }

  public get isFirstSelected(): boolean {
    return this.tabItemIndexes.current === 0;
  }

  public register(tabItemInstance: any): void {
    if (this.isTabItemComponent(tabItemInstance)) {
      this.tabItems.push(tabItemInstance);
      this.setNavItem(tabItemInstance);
    }
  }

  public unregister(tabItemInstance: any): void {
    if (this.isTabItemComponent(tabItemInstance)) {
      this.tabItems.splice(this.findIndexTab(tabItemInstance), 1);
      this.navItems.splice(tabItemInstance.ownNavItemIndex, 1);
      this.tabItemIndexes.last = this.navItems.length - 1;
    }
  }

  public isTabItemComponent(component: { $options: any }) {
    return component.$options?.name === "TabItem";
  }

  public setNavItem(tabItem: TabItem): void {
    this.navItems.push({
      model: tabItem.model,
      name: tabItem.name,
      nameSlot: tabItem.$slots.name?.[0],
    });
    this.tabItemIndexes.last = this.navItems.length - 1;
  }

  public activeTabItem(tabItem: NavItem): void {
    this.tabItemActive = tabItem;
    this.$emit('input', this.findIndexTab(tabItem));
  }

  public switchTabItem(to: 'next' | 'prev'): void {
    const { current, last } = this.tabItemIndexes;
    const switchTab = (count: number) => {
      const tabItem = this.navItems?.[current + count];
      if (tabItem) {
        this.activeTabItem(tabItem);
      }
    }

    if (to === 'next' && current < last) {
      switchTab(1);
    }
    if (to === 'prev' && current > 0) {
      switchTab(-1);
    }
  }

  private findIndexTab(tabItem: {model: string}): number {
    return this.tabItems.findIndex((el) => el.model == tabItem.model);
  }
}
