
import TabItem from '@/components/ui/tabs/TabItem.vue';
import Tabs from '@/components/ui/tabs/Tabs.vue';
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import { labelsStore } from '@/store';
import { AllLabels, CaseData } from '@/interfaces';
import { caseDataProviderKey } from '@/utils';
import CaseListItemFirstTarget from '@/hubble/CaseListPage/CaseListItemFirstTarget.vue';
import QuestionnaireInfo from './QuestionnaireInfo.vue';
import SystemInfo from './SystemInfo.vue';

@Component({
  components: {
    Tabs,
    TabItem,
    CaseListItemFirstTarget,
    SystemInfo,
    QuestionnaireInfo
  }
})
export default class CaseInsights extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  public get duration(): string {
    const MS_PER_MINUTE = 1000 * 60;
    const totalMinutes = Math.floor(this.caseData.caseDurationMs / MS_PER_MINUTE);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h:${minutes}m`;
  }
}
