
import { CaseMode } from '@/enums';
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import { AllLabels, CaseData } from '@/interfaces';
import { caseDataProviderKey } from '@/utils';
import { labelsStore } from '@/store';

@Component({})
export default class CaseListItemFirstTarget extends Vue {
  
  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  get displayTemperature(): string {
    return this.caseData.firstTargetTemperature.toString() + "°C";
  }

  get circleClass(): string {
    switch (this.caseData.caseMode) {
      case CaseMode.Cooling:
        return 'cooling';
      case CaseMode.Normothermia:
        return 'normothermia';
      case CaseMode.Warming:
        return 'warming';
      case CaseMode.Unknown:
      default:
        return 'unknown';
    }
  }

  public get isCooling(): boolean {
    return this.caseData.caseMode == CaseMode.Cooling;
  }
  public get isWarming(): boolean {
    return this.caseData.caseMode == CaseMode.Warming;
  }
  public get isNormothermia(): boolean {
    return this.caseData.caseMode == CaseMode.Normothermia;
  }
  public get isUnknown(): boolean {
    return this.caseData.caseMode == CaseMode.Unknown;
  }
}
