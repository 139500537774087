
import TabItem from '@/components/ui/tabs/TabItem.vue';
import Tabs from '@/components/ui/tabs/Tabs.vue';
import { Component, InjectReactive, Vue } from 'vue-property-decorator';
import { labelsStore } from '@/store';
import CaseKeyMetricsTimeWithinRange from './CaseKeyMetricsTimeWithinRange.vue'
import { AllLabels, CaseData } from '@/interfaces';
import { caseDataProviderKey, convertMsToMinutes } from '@/utils';
import { CaseMode } from '@/enums';

@Component({
  components: {
    CaseKeyMetricsTimeWithinRange,
    Tabs,
    TabItem
  }
})
export default class CaseKeyMetrics extends Vue {

  @InjectReactive(caseDataProviderKey)
  public caseData!: CaseData;

  public get labels(): AllLabels {
    return labelsStore.labels;
  }

  get caseMode(): string {
    return this.caseData.caseMode;
  }

  get hasCooling(): boolean {
    switch (this.caseData.rawCaseMode)
    {
      case 'Cooling':
      case 'NormothermiaCooling':
        return true;
      default:
        return false;
    }
  }

  get hasWarming(): boolean {
    switch (this.caseData.rawCaseMode)
    {
      case 'Warming':
      case 'NormothermiaWarming':
        return true;
      default:
        return false;
    }
  }

  get showFeverBurden(): boolean {
    // disable fever burden
    return false;
    // return !this.isWarming;
  }

  get isWarming(): boolean {
    return this.caseMode == CaseMode.Warming;
  }

  get hasInductionTime(): boolean {
    return this.caseData.inductionTimeMs != 0;
  }

  get coolingRate(): string {
    return this.caseData.coolingRate + '°C/h';
  }

  get rewarmingRate(): string {
    return this.caseData.rewarmingRate + '°C/h';
  }
  
  get warmingRate(): string {
    return this.caseData.warmingRate + '°C/h';
  }

  get feverBurden(): string {
    return this.caseData.feverBurden.toFixed(2) + '°C h';
  }

  get timeToTarget(): string {
    return convertMsToMinutes(this.caseData.timeToTargetMs02) + ' min';
  }
}
